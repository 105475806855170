function arePropsEqual(prevProps, nextProps, key) {
  try {
    return JSON.stringify(prevProps[key]) === JSON.stringify(nextProps[key]);
  } catch (e) {
    console.log(e);
    return false;
  }
}

export default arePropsEqual;
