import CloseDrawer from 'components/molecules/close-drawer';
import { QueueContainter, Queue, QueueNumber, TextWrapper, ScheduleAppointment } from './styles';
import Button from 'okadoc-component-ui/lib/Button';
import { DRAWER_TYPE } from 'containers/video/chat-view/constants';
import useAction from './actions';

const QueueComponent = ({ data, onClickJoinCall, t }) => {
  const {
    fg_practitioner_on_call: practitionerOnCall = false,
    waiting_estimation: waitingEstimation = '',
    total_current_queue: totalQueue = '',
    practitioner_status: practitionerStatus = ''
  } = data;

  const { isJoinCall, alertType, setAlertType, showAlert, setShowAlert } = useAction({
    practitionerOnCall,
    practitionerStatus,
    totalQueue
  });

  return (
    <>
      <QueueContainter>
        <div className="container">
          <div className="chatbox-header-menu d-flex justify-content-between">
            {!isJoinCall && (
              <>
                <TextWrapper>
                  <div className="mb-2">
                    <span className="list-group__title">{t("You're next in the queue")}</span>
                  </div>
                  <div className="mb-2">{t(`${waitingEstimation} estimated waiting`)}</div>
                  <ScheduleAppointment
                    onClick={() => {
                      setShowAlert(true);
                      setAlertType(DRAWER_TYPE.SCHEDULE_APPOINTMENT);
                    }}
                  >
                    {t('or schedule an appointment')}
                  </ScheduleAppointment>
                </TextWrapper>
                <Queue>
                  <div>
                    <QueueNumber>{t(totalQueue)}</QueueNumber>
                  </div>
                  <span>{t('in queue')}</span>
                </Queue>
              </>
            )}

            {isJoinCall && (
              <div className="container">
                <div className="chatbox-header-menu d-flex justify-content-between">
                  <TextWrapper className="list-group__title align-middle">
                    {t('The doctor is ready for you!')}
                  </TextWrapper>
                  <Button onClick={onClickJoinCall}>{t('Join Call')}</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </QueueContainter>
      <CloseDrawer show={showAlert} onHide={() => setShowAlert(false)} type={alertType} />
    </>
  );
};

export default QueueComponent;
