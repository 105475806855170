import create from 'zustand';
import { CONSULTATION_STATUS } from 'libs/constant';

const useGlobalConfigStore = create(set => ({
  webcamOn: false,
  micOn: false,
  isEndSession: false,
  leavedScreenStates: null,
  webcamDeviceId: null,
  networkBarEnabled: true,
  micDeviceId: null,
  meetingStarted: false,
  isShowAppDrawerUpload: false,
  isPatientJoinTheCall: false,
  participantStatus: { practitioner: CONSULTATION_STATUS.YET_TO_JOIN, patient: CONSULTATION_STATUS.YET_TO_JOIN },
  isShowCSAT: false,
  showCSATDone: false,
  toggleWebcamOn: () => set(state => ({ webcamOn: !state.webcamOn })),
  toggleMicOn: () => set(state => ({ micOn: !state.micOn })),

  setVideoEnabled: videoEnabled => set(() => ({ webcamOn: videoEnabled })),
  setMicEnabled: micEnabled => set(() => ({ micOn: micEnabled })),

  setIsEndSession: isEndSession => set(() => ({ isEndSession })),
  toggleState: updatedState => set(state => ({ ...state, ...updatedState })),
  setWebcamDeviceId: webcamDeviceId => set(() => ({ webcamDeviceId })),
  setMicDeviceId: micDeviceId => set(() => ({ micDeviceId })),
  setNetworkBarEnabled: networkBarEnabled => set(() => ({ networkBarEnabled })),
  setMeetingStarted: meetingStarted => set(() => ({ meetingStarted })),
  setIsShowAppDrawerUpload: value => set(() => ({ isShowAppDrawerUpload: value }))
}));

export default useGlobalConfigStore;
