/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { useMeeting } from '@videosdk.live/react-sdk';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useGlobalConfigStore } from 'zustandStore';

import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import LoadingModal from 'components/LoadingModal';
import UploadDocument from 'components/organisms/upload-document';
import ParticipantsView from '../partial/participant-view';
import ChatView from '../chat-view';
import InfoView from '../partial/info-view-event';
import { Container } from './styles';

const VideoContainer = ({
  country,
  isPatient,
  lang,
  participantType,
  showChat,
  showFullChat,
  showInfo,
  showVideo,
  setShowFullChat,
  setShowVideo,
  setShowChat,
  setShowInfo,
  setIsUnread,
  wlVersion,
  isXToken
}) => {
  const [sideBarRef, setSideBarRef] = useState(null);
  const [sideBarVideoRef, setSideBarVideoRef] = useState(null);

  const uploadDocumentRef = useRef(null);

  const { t } = useTranslation();
  const isMobile = mobileBreakPoint();
  const { isMeetingJoined } = useMeeting();

  const isShowAppDrawerUpload = useGlobalConfigStore(state => state.isShowAppDrawerUpload);
  const isEndSession = useGlobalConfigStore(state => state.isEndSession);
  const setIsShowAppDrawerUpload = useGlobalConfigStore(state => state.setIsShowAppDrawerUpload);

  const isOldWLVersion = +wlVersion === 1;
  const chatActive = !(isMobile && showInfo) && (showChat || showFullChat);
  const videoCallActive = isMobile ? showVideo && !showChat && !showInfo : showVideo;

  useEffect(() => {
    if (!isPatient && videoCallActive && !chatActive) {
      setIsShowAppDrawerUpload(false);
    }
  }, [isPatient, videoCallActive, chatActive]);

  const showLoading = () => {
    if (isEndSession) {
      return false;
    }

    return !isMeetingJoined && !isEndSession;
  };

  return (
    <>
      <Container isOldWLVersion={isOldWLVersion}>
        {videoCallActive && (
          <div className={classnames('video-embed active video-joined')} id="okadocvideoconsultation">
            <ParticipantsView
              showVideo={showVideo}
              setShowInfo={setShowInfo}
              setShowChat={setShowChat}
              setShowFullChat={setShowFullChat}
              setShowVideo={setShowVideo}
              participantType={participantType}
              setIsUnread={setIsUnread}
            />
          </div>
        )}
        {chatActive && (
          <div ref={setSideBarVideoRef} className={`${showFullChat ? 'chat' : 'sidebar'} chat--sidebar`}>
            <ChatView
              showVideo={showVideo}
              setShowVideo={setShowVideo}
              showChat={showChat}
              setShowChat={setShowChat}
              wlVersion={wlVersion}
              country={country}
              lang={lang}
              isPatient={isPatient}
              showFullChat={showFullChat}
              setShowFullChat={setShowFullChat}
              saveFacilityDocument={uploadDocumentRef.current?.saveFacilityDocument}
            />
          </div>
        )}
        <div ref={setSideBarRef} className={classnames('sidebar', 'chat--sidebar', { hide: !showInfo })}>
          <InfoView showVideo={showVideo} isXToken={isXToken} />
        </div>
        {/** Upload Document Screen For Doctor Web Only */}
        {!isPatient && (
          <UploadDocument
            ref={uploadDocumentRef}
            t={t}
            country={country}
            lang={lang}
            sideBarRef={videoCallActive && chatActive ? sideBarVideoRef : sideBarRef}
            isOpen={isShowAppDrawerUpload}
            isShowVideoScreen={videoCallActive && chatActive}
            wlVersion={wlVersion}
            onHide={() => setIsShowAppDrawerUpload(false)}
          />
        )}
      </Container>
      <LoadingModal active={showLoading()} />
    </>
  );
};

export default VideoContainer;
