// * based on https://react-bootstrap.github.io/components/modal/

import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import './styles.scss';

const BootstrapModal = ({ show = false, className = '', ...props }) => {
  const cls = classNames('bootstrap-modal', [className]);

  return <Modal backdropClassName="bootstrap-modal" className={cls} show={show} {...props} />;
};

BootstrapModal.Header = Modal.Header;
BootstrapModal.Title = Modal.Title;
BootstrapModal.Body = Modal.Body;
BootstrapModal.Footer = Modal.Footer;

BootstrapModal.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string
};

export default BootstrapModal;
