import Svg from 'components/Svg';
import { useMemo, useState } from 'react';

const Star = ({ rateIdx, starIdx, hoveredIdx }) => {
  const icon = useMemo(() => {
    const deselectedIcon = 'https://img.okadoc.com/photos/block_images/img/icon/ic-star-grey.svg';
    const selectedIcon = 'https://img.okadoc.com/photos/block_images/img/icon/ic-star-color.svg';

    // start is being hovered
    if (hoveredIdx >= 0 || typeof rateIdx === 'undefined') {
      return hoveredIdx < starIdx ? deselectedIcon : selectedIcon;
    }

    return starIdx <= rateIdx ? selectedIcon : deselectedIcon;
  }, [rateIdx, starIdx, hoveredIdx]);

  return <Svg name={icon} alt="Rating" className="img-responsive" external />;
};

const StarRating = props => {
  const { onChange, stars, rate } = props;

  const [hoveredIdx, setHoveredIdx] = useState(-1);

  const onHover = starIdx => () => setHoveredIdx(starIdx);

  const onChangeStar = (option, idx) => () => {
    onChange(option, idx);
  };

  return (
    <div className="d-flex flex-nowrap justify-content-center">
      {stars?.length > 0 &&
        stars.map((star, idx) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={onChangeStar(star, idx)}
              onMouseEnter={onHover(idx)}
              onMouseLeave={onHover(-1)}
              key={`rate#${idx}`}
            >
              <Star rateIdx={rate} starIdx={idx} hoveredIdx={hoveredIdx} />
            </span>
          );
        })}
    </div>
  );
};

export default StarRating;
