import React, { Fragment } from 'react';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Png = ({ name, className, external = false, lazyLoad = false, alt, ...rest }) => {
  let source = '';
  if (external) source = name;
  else source = require(`../assets/images/${name}.png`);

  return (
    <Fragment>
      {lazyLoad && (
        <LazyLoadImage
          effect="blur"
          className={classNames({ [className]: className })}
          src={source}
          threshold={10}
          {...rest}
        />
      )}
      {!lazyLoad && <img alt={alt} src={source} className={classNames({ [className]: className })} {...rest} />}
    </Fragment>
  );
};

export default Png;
