import to from '../../await-to';
import OkaAxios from '../../axios';

export default class AppointmentAPI {
  static GetAccountDetail = async isDoctor => {
    const endpoint = isDoctor ? '/auth/doctor/me' : '/account';

    return to(OkaAxios.account.get(endpoint));
  };
}
