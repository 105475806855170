// * based on https://react-bootstrap.github.io/components/offcanvas/

import Offcanvas from 'react-bootstrap/Offcanvas';
import PropTypes from 'prop-types';

import './styles.scss';

const Tray = ({ show = false, placement = 'start', ...props }) => {
  return <Offcanvas show={show} placement={placement} {...props} />;
};

Tray.Header = Offcanvas.Header;
Tray.Title = Offcanvas.Title;
Tray.Body = Offcanvas.Body;

Tray.propTypes = {
  show: PropTypes.bool,
  placement: PropTypes.oneOf(['start', 'end', 'top', 'bottom'])
};

export default Tray;
