import Colors from 'okadoc-component-ui/lib/Colors';
import { createGlobalStyle } from 'styled-components';

export const DocumentPreviewTrayGlobalStyle = createGlobalStyle`
  .document-preview {
    &.offcanvas-bottom {
      min-height: 100vh !important;


      .offcanvas-title {
        &.h5 {
          color: ${Colors.text.RhinoGrey};
          font-size: 18px;
          font-weight: 700;
        }

        max-width: 167px;
        order: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;
