import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
let theDate = dayjs.extend(utc);
theDate = theDate.extend(advancedFormat);
theDate = theDate.extend(localizedFormat);
theDate = theDate.extend(relativeTime);

export const thisDate = theDate;
export const YEAR_DIFF = 100;

export const secondInMinute = 60;
export const secondInHour = 60 * secondInMinute;
export const secondInDay = 24 * secondInHour;

export const secondToHours = sec => {
  if (sec <= 0) {
    return {
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
      seconds: 0
    };
  }
  const secNum = parseInt(sec, 10);
  const days = Math.floor(secNum / secondInDay);
  const hours = Math.floor(secNum / secondInHour);
  const minutes = Math.floor((secNum - hours * secondInHour) / 60);
  const seconds = secNum - hours * secondInHour - minutes * 60;

  return {
    day: days,
    hour: hours,
    minute: minutes,
    second: seconds,
    seconds: sec
  };
};

export const getRemainingTime = to => {
  const now = theDate().local();
  const diffc = to.unix() - now.unix();
  let seconds = diffc;
  let day = 0;
  let hours = 0;
  let minutes = 0;
  if (diffc > secondInDay) {
    day = Math.round(seconds / secondInDay);
    seconds = seconds % (day * secondInDay);
  }
  if (seconds > secondInHour) {
    hours = Math.round(seconds / secondInHour);
    seconds = seconds % (hours * secondInHour);
  }
  if (seconds > secondInMinute) {
    minutes = Math.round(seconds / secondInMinute);
    seconds = seconds % (minutes * secondInMinute);
  }
  const r = {
    day: day,
    hour: hours,
    minute: minutes,
    second: seconds,
    seconds: diffc
  };
  return r;
};

const todayDate = dayjs();
const tomorrowDate = dayjs().add(1, 'days');

export const FORMAT_12HOURS = '12h';
export const FORMAT_24HOURS = '24h';
export const HOURS_DEFAULT_FORMAT = 'hh:mm';
export const getTimeFormat = format => {
  return format === FORMAT_24HOURS ? 'HH:mm' : HOURS_DEFAULT_FORMAT;
};

export const dateLibrary = date => {
  if (!date) {
    return dayjs();
  }
  return dayjs(date);
};

export default class Helper {
  static MORNING = 'morning';

  static AFTERNOON = 'afternoon';

  static EVENING = 'evening';

  static setLocale = locale => {
    if (locale) {
      dayjs.locale(locale);
    }
  };

  static isToday = date => {
    const todayD = todayDate.format('YYYY-MM-DD');
    const dateToCheck = dayjs(date).format('YYYY-MM-DD');
    return dayjs(todayD).isSame(dateToCheck);
  };

  static isTodayByDay = (day = '') => {
    const todayD = todayDate.format('dddd').toLowerCase();
    const dayToCheck = day.toLowerCase();
    return todayD === dayToCheck;
  };

  static format = (date, format = '') => dayjs(date).format(format);

  static isTomorrow = date => {
    const tomorrowD = tomorrowDate.format('YYYY-MM-DD');
    const dateToCheck = dayjs(date).format('YYYY-MM-DD');
    return dayjs(tomorrowD).isSame(dateToCheck);
  };

  static isBiggerThanCurrent = date => {
    const todayD = todayDate.format('YYYY-MM-DD HH:mm:ss');
    const toCheck = dayjs(date).format('YYYY-MM-DD HH:mm:ss');

    return dayjs(todayD).isBefore(toCheck);
  };
}
