import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DownloadSimple } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';
import PDFViewer from 'components/atoms/pdf-viewer';
import Tray from 'components/atoms/tray';
import { downloadFile } from './actions';
import { DocumentPreviewTrayGlobalStyle } from './styles';

const DocumentPreviewTray = ({ file = { url: '', fileName: '' }, show = false, onHide = () => {} }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { fileName, url } = file;

  useEffect(() => {
    if (show) {
      setNumPages(null);
      setPageNumber(1);
    }
  }, [show]);

  const onClickDownload = () => {
    downloadFile(url, fileName);
  };

  const onClickPreviousPage = () => {
    changePage(-1);
  };

  const onClickNextPage = () => {
    changePage(1);
  };

  const onDocumentLoadSuccess = ({ numPages: numberPage }) => {
    setNumPages(numberPage);
    setPageNumber(1);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  return (
    <>
      <DocumentPreviewTrayGlobalStyle />
      <Tray className="document-preview" show={show} placement="bottom" onHide={onHide}>
        <Tray.Header closeButton>
          <Tray.Title>{fileName}</Tray.Title>
          <DownloadSimple
            color={Colors.primary.NavyBlue}
            size={24}
            weight="light"
            onClick={onClickDownload}
            style={{ cursor: 'pointer', order: 2 }}
          />
        </Tray.Header>
        <Tray.Body>
          <PDFViewer file={url} onLoadSuccess={onDocumentLoadSuccess}>
            {({ width }) => (
              <>
                <PDFViewer.Page pageNumber={pageNumber} width={width} />
                {numPages > 1 && (
                  <PDFViewer.Pagination>
                    <PDFViewer.Pagination.Left disabled={pageNumber <= 1} onClick={onClickPreviousPage} />
                    <PDFViewer.Pagination.Text numPages={numPages} pageNumber={pageNumber} />
                    <PDFViewer.Pagination.Right disabled={pageNumber >= numPages} onClick={onClickNextPage} />
                  </PDFViewer.Pagination>
                )}
              </>
            )}
          </PDFViewer>
        </Tray.Body>
      </Tray>
    </>
  );
};

DocumentPreviewTray.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired
  }),
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default DocumentPreviewTray;
