import styled from 'styled-components';

export const PDFViewerContainer = styled.div`
  .react-pdf {
    &__Document {
      max-width: 100%;
      position: relative;
    }

    &__Page {
      &__annotations {
        display: none;
      }
    }

    &__message {
      padding: 0px 32px;
    }

    &__pagination {
      display: flex;
      flex: 1 1 0;
      align-items: center;
      line-height: normal;
      justify-content: center;
      margin-top: 16px;

      button {
        display: flex;
        border: none;
        background: transparent;
        padding: 8px;
        border-radius: 4px;

        &:disabled {
          opacity: 0.5;
        }

        &:not(:disabled):hover {
          background: transparent;
        }
      }
    }
  }
`;
