/* eslint-disable no-undefined */
import { useContext } from 'react';
import { InitContext } from '../contexts/init';

const useService = () => {
  const context = useContext(InitContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useService was used outside of its Provider');
  }

  return context;
};

export default useService;
