import styled, { createGlobalStyle } from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const AppDrawerGlobalStyle = createGlobalStyle`
  .app-drawer {
    &.offcanvas {
      &-bottom {
        border-radius: 20px 20px 0px 0px;
        border-top: 0;

        height: ${props => props.height};
      }
    }

    .offcanvas-body {
      padding: 16px;
    }
  }
`;

export const AppDrawerHeader = styled.div`
  border-bottom: 1px solid #e8e9ea;
  padding: 14px 20px;
  position: relative;
`;

export const AppDrawerHeaderTitle = styled.h5`
  color: ${Colors.text.RhinoGrey};
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;
