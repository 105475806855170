/* eslint-disable no-undefined */
import React from 'react';
import Png from 'components/Png';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import InfoView from '../video/partial/info-view';
import useChat from 'libs/hooks/useChat';
import useCountdown from 'libs/hooks/useCountdown';
import { Container, Title, CountdownSection, Content } from './styles';
import RemainingTime from './RemainingTime';

const priorTime = process.env.REACT_APP_TELEMEDICINE_PRIOR_TIME || 600; // default is 10 minutes;
const priorInHour = +priorTime / 3600;
const priorInMinute = +priorTime / 60;
const isHour = priorInHour >= 1;

const WaitingRoom = ({ isDoctor = false, showInfo = false, isTestingMode = false, goToPreMeeting, t }) => {
  const {
    value: { appointment }
  } = useChat();
  const isMobile = mobileBreakPoint();
  const handleChangeScreen = () => {
    if (typeof goToPreMeeting === 'function') {
      goToPreMeeting(true);
    }
  };

  const { timeLeft } = useCountdown({
    startTime: appointment?.startTime,
    endTime: appointment?.endTime,
    onComplete: handleChangeScreen
  });

  return (
    <Container>
      {(!isMobile || !showInfo) && (
        <Content>
          <Title>{isDoctor ? t('Your appointment will be available in') : t('Your doctor will be available in')}</Title>
          {timeLeft && (
            <CountdownSection className="alert alert--success">
              <h6 className="text-black">
                <RemainingTime remainingTime={timeLeft} isTestingMode={isTestingMode} />
              </h6>
            </CountdownSection>
          )}
          <div className="text-center">
            <Png
              name="https://img.okadoc.com/photos/block_images/img/icon/ic-specialists.svg"
              className="img-responsive"
              alt="appointment not ready"
              width="150"
              external
            />
          </div>
          <p className="text-gray text-center">{`Attendees can join ${isHour ? priorInHour : priorInMinute} ${
            isHour ? 'hour' : 'minutes'
          } prior to the schedule start time.`}</p>
          <p className="text-gray text-center">{`If the consultation does not become available ${
            isHour ? priorInHour : priorInMinute
          } ${isHour ? 'hour' : 'minutes'} prior to the start time, please reload the page.`}</p>
        </Content>
      )}
      {showInfo && (
        <div className="sidebar chat--sidebar">
          <InfoView />
        </div>
      )}
    </Container>
  );
};

export default WaitingRoom;
