import { CaretRight } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';

const Right = ({ ...props }) => (
  <button type="button" {...props}>
    <CaretRight size={16} color={Colors.grayscale.RhinoGrey} />
  </button>
);

export default Right;
