import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import durationFormat from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
let theDate = day.extend(utc);
theDate = theDate.extend(advancedFormat);
theDate = theDate.extend(localizedFormat);
theDate = theDate.extend(relativeTime);
theDate = theDate.extend(durationFormat);

export const sec2time = timeInSeconds => {
  const pad = function (num, size) {
    return ('000' + num).slice(size * -1);
  };
  const time = parseFloat(timeInSeconds).toFixed(3);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);

  return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
};

export const getDate = (dateParam = '') => {
  // Sun, Dec 13, 2020
  return theDate(dateParam).format('ddd, MMM DD, YYYY');
};

export const getDuration = (timestamp = '') => {
  return theDate.duration(timestamp);
};

export const getFullDatetime = day().utc();

export const getTime = (dateParam = '') => {
  // 09:30 AM - 10:00 AM
  return theDate(dateParam).format('hh:mm A');
};

export const getBirthDay = (dateParam = '') => {
  // 15 / 08 / 1985
  return theDate(dateParam).format('DD / MM / YYYY');
};

export const secondInMinute = 60;
export const secondInHour = 60 * secondInMinute;
export const secondInDay = 24 * secondInHour;

export const TimeRemainSeconds = totalSec => {
  const diffc = totalSec;
  let seconds = diffc;

  let day = 0;
  let hours = 0;
  let minutes = 0;
  if (diffc > secondInDay) {
    day = Math.round(seconds / secondInDay);
    seconds = seconds % (day * secondInDay);
  }
  if (seconds > secondInHour) {
    hours = Math.round(seconds / secondInHour);
    seconds = seconds % (hours * secondInHour);
  }

  minutes = Math.ceil(seconds / secondInMinute);
  seconds = seconds % (minutes * secondInMinute);

  const r = {
    day: day,
    hour: hours,
    minute: minutes,
    second: seconds,
    seconds: totalSec
  };

  return r;
};

export const getTimeSecond = (date = '') => {
  const datetime = date !== '' ? theDate(date) : theDate();
  return datetime.local().utc();
};

export const getRemainingTime = (to, remainInSeconds = 0) => {
  const now = theDate().local();
  const diffc = to.unix() - now.unix();
  let seconds = diffc;
  let day = 0;
  let hours = 0;
  let minutes = 0;
  if (diffc > secondInDay) {
    day = Math.floor(seconds / secondInDay);
    seconds = seconds % (day * secondInDay);
  }
  if (seconds > secondInHour) {
    hours = Math.floor(seconds / secondInHour);
    seconds = seconds % (hours * secondInHour);
  }
  if (seconds > secondInMinute) {
    minutes = Math.floor(seconds / secondInMinute);
    seconds = seconds % (minutes * secondInMinute);
  }
  const r = {
    day: day,
    hour: hours,
    minute: minutes,
    second: seconds,
    seconds: diffc // remainInSeconds >0 ? remainInSeconds : diffc
  };
  return r;
};

export const getFormatTime = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;

  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export default theDate;
