import { ODD_SSE_EVENTS as EVENTS, ODD_SSE_CONFIG } from 'libs/constant';
import useChat from 'libs/hooks/useChat';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

const ODD_SSE_URL = `${process.env.REACT_APP_PIGEON_API_URL}/event/stream/appointment-telemedicine`;

const ODDSSECtx = createContext({
  initialized: false,
  message: {},
  sse: null
});

const SSEODDProvider = ({ children }) => {
  const {
    value: { appointment, isDoctor }
  } = useChat();

  const sseRef = useRef(null);
  const [message, setMessage] = useState({});
  const [initialized, setInitialized] = useState(false);
  const subscribers = useRef({
    [EVENTS.TOTAL_QUEUE]: [],
    [EVENTS.UPDATE_PARTICIPANT]: [],
    [EVENTS.UPLOADED_DOCUMENT]: [],
    [EVENTS.WAITING_TIME]: []
  });

  const configType = isDoctor ? ODD_SSE_CONFIG.DOCTOR : ODD_SSE_CONFIG.PATIENT;

  // trigger handlers of `onmessage` event callback
  useEffect(() => {
    const { type, data } = message;
    if (type && data && subscribers.current?.[type]?.length > 0) {
      subscribers.current[type].forEach(subscriber => {
        subscriber(data);
      });
    }
  }, [message]);

  useEffect(() => {
    if (!initialized && !sseRef?.current && appointment?.appointmentNumber && appointment?.isODDConsultation) {
      const oddSSEUrl = `${ODD_SSE_URL}-${appointment?.appointmentNumber}`.toLowerCase();
      sseRef.current = new EventSource(oddSSEUrl);
      sseRef.current.onmessage = eventStream => {
        const newEventMessage = getStreamPayload(eventStream);
        setMessage({ ...newEventMessage });
      };

      // close connection on error
      sseRef.current.onerror = () => {
        sseRef.current?.close();
        sseRef.current = null;
        setInitialized(false);
      };

      setInitialized(true);
    }

    return () => {
      sseRef?.current && initialized && sseRef.current?.close?.();
    };
  }, [initialized, appointment?.appointmentNumber, appointment?.isODDConsultation]);

  const getStreamPayload = eventStream => {
    let eventData = { type: '', data: {} };
    try {
      if (eventStream?.data) {
        eventData = JSON.parse(eventStream.data) || {};
      }
    } catch (error) {
      //
    }
    return eventData;
  };

  const isSubscriberValid = subscriber => {
    // default config if subscriber.config is not available and
    // callback handler will be called in teleconsultation under patient and doctor
    let hasConfig = [ODD_SSE_CONFIG.DOCTOR, ODD_SSE_CONFIG.PATIENT].includes(configType);

    // if subscriber.config is available execute this functions. ex: ["patient"] or ["doctor"] or ["patient", "doctor"]
    if (Array.isArray(subscriber.config)) {
      hasConfig = subscriber.config.includes(configType);
    }
    return (
      subscriber?.type &&
      [EVENTS.TOTAL_QUEUE, EVENTS.UPDATE_PARTICIPANT, EVENTS.UPLOADED_DOCUMENT, EVENTS.WAITING_TIME].includes(
        subscriber.type
      ) &&
      hasConfig &&
      typeof subscriber?.handler === 'function'
    );
  };

  /**
   * @param {Object.<String, Function>} subscriber - A callback handler of specific type event source
   * @param {String} subscriber.type - Event source type that can be 'uploaded_document'|'update_participant'|'total_queue'
   * @param {Array} subscriber.config - Event source config to set the callback event. If not available, will using default value as ["patient", "doctor"]
   * @param {Function} subscriber.handler - A callback function that will be called and accept event source payload as params
   */
  const addSubscriber = useCallback(subscriber => {
    if (isSubscriberValid(subscriber)) {
      subscribers.current[subscriber.type].push(subscriber.handler);
    }
  }, []);

  const ctxValues = useMemo(
    () => ({
      sse: sseRef?.current,
      message,
      addSubscriber
    }),
    [message, addSubscriber]
  );

  return <ODDSSECtx.Provider value={ctxValues}>{children}</ODDSSECtx.Provider>;
};

export const useODDSSEContext = () => useContext(ODDSSECtx);

export default SSEODDProvider;
