import styled, { css } from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const ConsentFileName = styled.span`
  color: var(--color-primary);
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const ConsentError = styled.div`
  color: ${Colors.feedback.FireRed};
  display: ${props => (props.$isHide ? 'none' : 'block')};
  font-size: 13px;
  font-weight: 400;

  ${props =>
    props.$isDesktop
      ? css`
          padding-top: 16px;
        `
      : css`
          margin-top: -16px;
          padding-bottom: 24px;
        `}
`;
