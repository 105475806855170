import PropTypes from 'prop-types';
import { ArrowSquareOut, Info } from 'phosphor-react';
import Button from 'okadoc-component-ui/lib/Button';
import { useTranslation } from 'react-i18next';
import Colors from 'okadoc-component-ui/lib/Colors';
import BootstrapModal from 'components/atoms/bootstrap-modal';
import useAction from 'components/organisms/consent-modal/actions';
import { ConsentFileName, ConsentError } from 'components/organisms/consent-modal/styles';
import {
  ConsentDesktopScreenGlobalStyle,
  ConsenstDesktopIconContainer,
  ConsenstDesktopTitleContainer,
  ConsenstDesktopDescriptionContainer,
  ConsenstDesktopActionContainer,
  ConsenstDesktopActionItem,
  ConsentDesktopExtraFooter
} from './styles';

const ConsentDesktopScreen = ({
  open = false,
  consent: { fileName = '', description = '', url = '' },
  onHide = () => {},
  onSubmit = () => {}
}) => {
  const { t } = useTranslation();
  const { isDismiss, onClickDismiss } = useAction({ isConsentOpen: open });

  const onClickConsentFileName = () => {
    window.open(url, '_blank', 'noopener noreferrer');
  };
  const formatDescription = description.replace(/\d+/, params => {
    return `<a href='tel:${params}' data-phone='${params}' style="color: ${Colors.text.RhinoLight};text-decoration: underline;">${params}</a>`;
  });

  return (
    <>
      <ConsentDesktopScreenGlobalStyle />
      <BootstrapModal className="consent-modal" show={open} centered>
        <BootstrapModal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <ConsenstDesktopIconContainer>
              <Info color={Colors.primary.NavyBlue} size={48} weight="fill" />
            </ConsenstDesktopIconContainer>
            <ConsenstDesktopTitleContainer>
              <h4>{t('Telemedicine Consent')}</h4>
            </ConsenstDesktopTitleContainer>
            <ConsenstDesktopDescriptionContainer>
              {t('To proceed, please review and accept')}{' '}
              <ConsentFileName onClick={onClickConsentFileName}>{fileName}</ConsentFileName>{' '}
              <ArrowSquareOut
                color={Colors.primary.NavyBlue}
                size={16}
                weight="light"
                style={{ position: 'relative', top: 2 }}
              />
            </ConsenstDesktopDescriptionContainer>
            <ConsentError $isHide={!isDismiss}>{t('You must accept to proceed')}</ConsentError>
          </div>
          <ConsenstDesktopActionContainer>
            <ConsenstDesktopActionItem>
              <Button variant="outline-green" onClick={onClickDismiss} block>
                {t('Dismiss')}
              </Button>
            </ConsenstDesktopActionItem>
            <ConsenstDesktopActionItem>
              <Button onClick={onSubmit} block>
                {t('I Accept')}
              </Button>
            </ConsenstDesktopActionItem>
          </ConsenstDesktopActionContainer>
          <ConsentDesktopExtraFooter dangerouslySetInnerHTML={{ __html: formatDescription }} />
        </BootstrapModal.Body>
      </BootstrapModal>
    </>
  );
};

ConsentDesktopScreen.propTypes = {
  open: PropTypes.bool,
  consent: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ConsentDesktopScreen;
