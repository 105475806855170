import { EVENT_PAGE_NAVIGATION_ITEM, EVENT_TYPE_IFRAME } from 'libs/constant';
import { sendMessage } from 'libs/message';
import { getQueryParams } from 'libs/url';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalConfigStore } from 'zustandStore';

const useActions = consultationCompletedUrl => {
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const [domain, setDomain] = useState('');
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    const params = getQueryParams(window.location);
    setDomain(params?.domain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToAppointmentDetail = useCallback(() => {
    setIsRedirecting(true);
    sendMessage({
      message: {
        type: EVENT_TYPE_IFRAME.PAGE_NAVIGATION,
        data: {
          type: EVENT_PAGE_NAVIGATION_ITEM.APPOINTMENT_DETAIL
        }
      },
      domain
    });
  }, [domain]);

  const backToChatHistory = useCallback(() => {
    toggleState({ isShowCSAT: false, showCSATDone: true });
  }, [toggleState]);

  const goToConsultationCompletedUrl = () => {
    if (consultationCompletedUrl) {
      window.parent.location.href = consultationCompletedUrl;
    }
  };

  return useMemo(
    () => ({
      isRedirecting,
      goToAppointmentDetail,
      backToChatHistory,
      goToConsultationCompletedUrl
    }),
    [isRedirecting, goToAppointmentDetail, backToChatHistory]
  );
};

export default useActions;
