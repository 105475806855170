import React, { useRef, useEffect, Fragment, useState, useMemo } from 'react';
import ReactPlayer from 'react-player';
import { useMeeting, useParticipant, usePubSub, createCameraVideoTrack } from '@videosdk.live/react-sdk';

// Libs
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import useChat from 'libs/hooks/useChat';
import { CONSULTATION_STATUS, EVENT_PUBSUB } from 'libs/constant';
import { getFullDatetime } from 'libs/date';
import { getQualityScore } from 'libs/network';
import { useGlobalConfigStore } from 'zustandStore';
import { getStorage } from 'libs/storage';

// Components
import ToolBox from 'components/Toolbox';
import cn from 'classnames';
import NetworkIcon from 'components/NetworkIcon';

const chunk = arr => {
  const newArr = [];
  while (arr.length) newArr.push(arr.splice(0, 3));
  return newArr;
};

const ParticipantView = ({
  participantId,
  leave,
  toggleMic,
  toggleView,
  setToggleView,
  videoRef,
  hasMoreParticipant,
  isScreenSharing,
  webCamList,
  micList
}) => {
  const { publish: publishChat } = usePubSub(EVENT_PUBSUB.CHAT);
  const {
    value: { doctor, patient, isDoctor, appointment },
    action
  } = useChat();
  const { publish } = usePubSub(EVENT_PUBSUB.PARTICIPANTS_STATUS, {});
  const meeting = useMeeting();
  const webcamRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const micRef = useRef(null);
  const screenShareRef = useRef(null);
  const leavedScreenStates = useGlobalConfigStore(state => state.leavedScreenStates);
  const globalMicOn = useGlobalConfigStore(state => state.micOn);
  const globalWebcamOn = useGlobalConfigStore(state => state.webcamOn);
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const setWebcamDeviceId = useGlobalConfigStore(state => state.setWebcamDeviceId);
  const setMicDeviceId = useGlobalConfigStore(state => state.setMicDeviceId);
  const networkBarEnabled = useGlobalConfigStore(state => state.networkBarEnabled);
  const [toggleShowDeviceList, setToggleShowDeviceList] = useState(false);
  const [toggleShowMicDeviceList, setToggleShowMicDeviceList] = useState(false);
  const statsIntervalIdRef = useRef();
  const [score, setScore] = useState({});

  const [audioStats, setAudioStats] = useState({});
  const [videoStats, setVideoStats] = useState({});
  const [networkTable, setNetworkTable] = useState(false);

  const {
    webcamStream,
    micStream,
    screenShareStream,
    webcamOn,
    micOn,
    screenShareOn,
    isLocal,
    // setViewPort,
    getVideoStats,
    getAudioStats
  } = useParticipant(participantId);
  const [portrait, setPortrait] = useState(false);
  const handleLeave = () => {
    const remoteUser = !isDoctor ? doctor : patient;
    const userName = isDoctor ? remoteUser?.title : [remoteUser?.first_name, remoteUser?.last_name].join(' ');

    const eventEndCall = {
      ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
      format: 'video_end',
      message: 'video_end',
      text: 'video_end',
      time: getFullDatetime,
      sender: !isDoctor ? 'patient' : 'doctor'
    };
    const eventPayload = JSON.stringify(eventEndCall);

    action.saveChat({
      appointmentNumber: appointment.appointmentNumber,
      message: eventEndCall
    });
    publishChat(eventPayload, { persist: false });

    // keep the previous state
    toggleState({
      leavedScreenStates: { webcamOn, micOn },
      isPatientJoinTheCall: false
    });

    leave({
      webcamOn,
      micOn
    });
  };

  const screenShareMediaStream = useMemo(() => {
    if (screenShareOn) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(screenShareStream.track);
      return mediaStream;
    }
  }, [screenShareStream, screenShareOn]);

  const handleNetworkClick = () => {
    setNetworkTable(current => !current);
  };

  const updateStats = async () => {
    let stats = [];
    let audioStats = [];
    let videoStats = [];

    if (webcamStream) {
      stats = await getVideoStats();
    } else if (micStream) {
      stats = await getAudioStats();
    }

    if (webcamStream || micStream) {
      videoStats = await getVideoStats();
      audioStats = await getAudioStats();
    }

    const score = stats ? (stats.length > 0 ? getQualityScore(stats[0]) : 100) : 100;

    setScore(score);
    setAudioStats(audioStats);
    setVideoStats(videoStats);
  };

  const qualityStateArray = [
    { label: '', audio: 'Audio', video: 'Video' },
    {
      label: 'Latency',
      audio: audioStats && audioStats[0]?.rtt ? `${audioStats[0]?.rtt} ms` : '-',
      video: videoStats && videoStats[0]?.rtt ? `${videoStats[0]?.rtt} ms` : '-'
    },
    {
      label: 'Jitter',
      audio: audioStats && audioStats[0]?.jitter ? `${parseFloat(audioStats[0]?.jitter).toFixed(2)} ms` : '-',
      video: videoStats && videoStats[0]?.jitter ? `${parseFloat(videoStats[0]?.jitter).toFixed(2)} ms` : '-'
    },
    {
      label: 'Packet Loss',
      audio: audioStats
        ? audioStats[0]?.packetsLost
          ? `${parseFloat((audioStats[0]?.packetsLost * 100) / audioStats[0]?.totalPackets).toFixed(2)}%`
          : '-'
        : '-',
      video: videoStats
        ? videoStats[0]?.packetsLost
          ? `${parseFloat((videoStats[0]?.packetsLost * 100) / videoStats[0]?.totalPackets).toFixed(2)}%`
          : '-'
        : '-'
    },
    {
      label: 'Bitrate',
      audio: audioStats && audioStats[0]?.bitrate ? `${parseFloat(audioStats[0]?.bitrate).toFixed(2)} kb/s` : '-',
      video: videoStats && videoStats[0]?.bitrate ? `${parseFloat(videoStats[0]?.bitrate).toFixed(2)} kb/s` : '-'
    },
    {
      label: 'Frame rate',
      audio: '-',
      video:
        videoStats && (videoStats[0]?.size?.framerate === null || videoStats[0]?.size?.framerate === 'undefined')
          ? '-'
          : `${videoStats ? videoStats[0]?.size?.framerate : '-'}`
    },
    {
      label: 'Resolution',
      audio: '-',
      video: videoStats
        ? videoStats && videoStats[0]?.size?.width === null
          ? '-'
          : `${videoStats[0]?.size?.width}x${videoStats[0]?.size?.height}`
        : '-'
    },
    {
      label: 'Codec',
      audio: audioStats && audioStats[0]?.codec ? audioStats[0]?.codec : '-',
      video: videoStats && videoStats[0]?.codec ? videoStats[0]?.codec : '-'
    },
    {
      label: 'Cur. Layers',
      audio: '-',
      video:
        videoStats && !isLocal
          ? videoStats && videoStats[0]?.currentSpatialLayer === null
            ? '-'
            : `S:${videoStats[0]?.currentSpatialLayer || 0} T:${videoStats[0]?.currentTemporalLayer || 0}`
          : '-'
    },
    {
      label: 'Pref. Layers',
      audio: '-',
      video:
        videoStats && !isLocal
          ? videoStats && videoStats[0]?.preferredSpatialLayer === null
            ? '-'
            : `S:${videoStats[0]?.preferredSpatialLayer || 0} T:${videoStats[0]?.preferredTemporalLayer || 0}`
          : '-'
    }
  ];

  const webcamMediaStream = () => {
    if (webcamOn && (globalWebcamOn || !isLocal)) {
      const mediaStream = new MediaStream();
      if (webcamStream) {
        mediaStream.addTrack(webcamStream.track);
        mediaStreamRef.current = mediaStream;
        return mediaStream;
      }
    }
    if (!globalWebcamOn) meeting?.disableWebcam();
  };

  const handleClickOutside = () => {
    setToggleShowDeviceList(show => false);
    setToggleShowMicDeviceList(show => false);
  };

  const handleShowDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(show => !show);
  };

  const handleShowMicDeviceList = e => {
    e.stopPropagation();
    setToggleShowMicDeviceList(show => !show);
  };

  const handleChangeCamera = cameraId => {
    if (cameraId) {
      setWebcamDeviceId(cameraId);
      setToggleShowDeviceList(false);
      toggleState({ webcamOn: true });
      meeting?.changeWebcam(cameraId);
    }
  };

  const handleChangeMic = micId => {
    if (micId) {
      setMicDeviceId(micId);
      setToggleShowMicDeviceList(false);
      toggleState({ micOn: true });
      meeting?.changeMic(micId);
    }
  };

  const checkAndUpdatePortrait = () => {
    if (webcamStream) {
      const { height, width } = webcamStream.track.getSettings();
      console.log('[debug]: getSettings ', webcamStream.track.getSettings());
      if (height > width && !portrait) {
        setPortrait(true);
      } else if (height < width && portrait) {
        setPortrait(false);
      }
    } else {
      setPortrait(false);
    }
  };

  const handleLocalToggleMic = () => {
    if (!globalMicOn) {
      meeting?.unmuteMic();
      toggleState({ micOn: true });
    } else {
      meeting?.muteMic();
      toggleState({ micOn: false });
    }
  };

  const handleLocalToggleWebcam = () => {
    meeting?.toggleWebcam();
    if (!globalWebcamOn) {
      toggleState({ webcamOn: true });
    } else {
      toggleState({ webcamOn: false });
    }
  };

  useEffect(() => {
    if (micRef.current) {
      if (micOn) {
        try {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(micStream.track);

          micRef.current.srcObject = mediaStream;
          micRef.current.play().catch(error => console.error('videoElem.current.play() failed', error));

          if (!globalMicOn) meeting?.muteMic();
        } catch (err) {
          return; // do nothing
        }
      }
    }
  }, [micStream, micOn, globalMicOn]);

  useEffect(() => {
    if (meeting.isMeetingJoined) {
      const messageToSent = {
        message: {
          status: CONSULTATION_STATUS.IN_CONSULTATION,
          isDoctor: isDoctor
        },
        type: 'message'
      };
      publish(JSON.stringify(messageToSent), { persist: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting.isMeetingJoined]);

  useEffect(() => {
    if (networkBarEnabled) {
      if (webcamStream) {
        updateStats();

        if (statsIntervalIdRef.current) {
          clearInterval(statsIntervalIdRef.current);
        }

        statsIntervalIdRef.current = setInterval(updateStats, 10000);
      } else {
        if (statsIntervalIdRef.current) {
          clearInterval(statsIntervalIdRef.current);
          statsIntervalIdRef.current = null;
        }
      }

      return () => {
        if (statsIntervalIdRef.current) clearInterval(statsIntervalIdRef.current);
      };
    }
  }, [webcamStream, networkBarEnabled]);

  const shouldShowSmallContainer = useMemo(() => {
    if (!hasMoreParticipant && !toggleView) return false;
    if (hasMoreParticipant) return !toggleView;
  }, [hasMoreParticipant, toggleView]);

  const mainScreenClass = cn('video-container', {
    'video-container-small': shouldShowSmallContainer
  });

  const playerWrapper = cn('player-wrapper', {
    'video-container-small-wrapper': shouldShowSmallContainer
  });

  const participantContainerClass = cn('video-container', {
    'video-mobile-resolution': true
  });

  const videoConfig = {
    playsinline: true, // very very imp prop
    pip: false,
    light: false,
    controls: false,
    muted: true,
    playing: true
  };

  const heightActiveScreen = shouldShowSmallContainer ? 'auto' : '100%'; // set height local video player based on toggleView and participants join
  return (
    <>
      <div id="videoconference_page" className="video-wrapper">
        {webcamStream && networkBarEnabled && isLocal && (
          <div className="network-indicator-wrapper">
            <div
              className="network-indicator"
              onClick={e => {
                e.stopPropagation();
                handleNetworkClick();
              }}
            >
              <NetworkIcon
                color1={score > 7 ? '#3BA55D' : score > 4 ? '#F1CC4A' : '#FF5D5D'}
                color2={score > 7 ? '#3BA55D' : score > 4 ? '#F1CC4A' : '#FF5D5D'}
                color3={score > 7 ? '#3BA55D' : score > 4 ? '#F1CC4A' : '#FFDBDB'}
                color4={score > 7 ? '#3BA55D' : score > 4 ? '#69571F' : '#FFDBDB'}
              />
            </div>
            {networkTable && (
              <div className="network-indicator-table">
                <div
                  style={{
                    padding: 9,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: score > 7 ? '#3BA55D' : score > 4 ? '#faa713' : '#FF5D5D'
                  }}
                >
                  <div style={{ fontWeight: 600 }}>
                    {`Quality Score : ${score > 7 ? 'Good' : score > 4 ? 'Average' : 'Poor'}`}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {qualityStateArray.map((item, index) => {
                      return (
                        <div
                          key={`item-${index}`}
                          style={{
                            display: 'flex',
                            borderBottom: index === qualityStateArray.length - 1 ? '' : '1px solid #ffffff33'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              width: 120,
                              alignItems: 'center'
                            }}
                          >
                            {index !== 0 && (
                              <div
                                style={{
                                  fontSize: 12,
                                  marginTop: 6,
                                  marginBottom: 6,
                                  marginLeft: 8
                                }}
                              >
                                {item.label}
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderLeft: '1px solid #ffffff33'
                            }}
                          >
                            <div
                              style={{
                                fontSize: 12,
                                marginTop: 6,
                                marginBottom: 6,
                                width: 65,
                                textAlign: 'center'
                              }}
                            >
                              {item.audio}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderLeft: '1px solid #ffffff33'
                            }}
                          >
                            <div
                              style={{
                                fontSize: 12,
                                marginTop: 6,
                                marginBottom: 6,
                                width: 65,
                                textAlign: 'center'
                              }}
                            >
                              {item.video}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {micOn && micRef && <audio ref={micRef} autoPlay muted={isLocal || Boolean(leavedScreenStates)} />}

        {isLocal && (
          <ToolBox
            className="toolbox toolbox-wrapper toolbox-overlay"
            micOn={globalMicOn}
            webcamOn={globalWebcamOn}
            toggleView={toggleView}
            handleToggleMic={handleLocalToggleMic}
            onClickScreenShare={meeting?.toggleScreenShare}
            handleToggleWebcam={handleLocalToggleWebcam}
            handleLeave={handleLeave}
            handleView={setToggleView}
            handleChangeCamera={handleChangeCamera}
            handleChangeMic={handleChangeMic}
            handleShowDeviceList={handleShowDeviceList}
            handleShowMicDeviceList={handleShowMicDeviceList}
            showDeviceList={toggleShowDeviceList}
            showMicList={toggleShowMicDeviceList}
            deviceLists={webCamList}
            showScreenShare={isDoctor}
            micLists={micList}
            handleClickOutside={handleClickOutside}
            hasMultipleSources
            showSwitchView
            showEndCall
          />
        )}

        {isLocal && (!screenShareOn || !toggleView) && (
          <div className={mainScreenClass}>
            <div className={playerWrapper}>
              <ReactPlayer
                ref={webcamRef}
                {...videoConfig}
                playIcon={<></>}
                url={webcamMediaStream()}
                width="100%"
                height={heightActiveScreen}
                onError={err => {
                  console.log(err, 'participant video error');
                }}
              />
            </div>
          </div>
        )}
        {!isLocal && !screenShareOn && (
          <div className={participantContainerClass}>
            {!isLocal && !screenShareOn && (
              <ReactPlayer
                ref={webcamRef}
                {...videoConfig}
                playIcon={<></>}
                url={webcamMediaStream()}
                width="100%"
                height="100%"
                className="main-video-cam"
                onError={err => {
                  console.log(err, 'participant video error');
                }}
                onProgress={() => {
                  checkAndUpdatePortrait();
                }}
              />
            )}
          </div>
        )}
        {screenShareOn && (
          <div className={participantContainerClass}>
            <ReactPlayer
              ref={screenShareRef}
              {...videoConfig}
              playIcon={<></>}
              url={screenShareMediaStream}
              width="100%"
              height="initial"
              className="main-video-cam"
              onError={err => {
                console.log(err, 'participant video error');
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const ParticipantsView = ({
  participantType,
  setShowFullChat,
  setShowVideo,
  setShowChat,
  setShowInfo,
  setIsUnread
}) => {
  const micDeviceId = useGlobalConfigStore(state => state.micDeviceId);
  const setVideoEnabled = useGlobalConfigStore(state => state.setVideoEnabled);
  const setMicEnabled = useGlobalConfigStore(state => state.setMicEnabled);
  const [micList, setMicList] = useState([]);
  const [webCamList, setWebCamList] = useState([]);
  const setMicDeviceId = useGlobalConfigStore(state => state.setMicDeviceId);

  const mMeetingRef = useRef();
  const videoRef = useRef();
  // const videoDoctorRef = useRef();
  const isMobile = mobileBreakPoint();

  const initCam = async () => {
    const getWebcams = await meeting?.getWebcams();
    const webCams = getWebcams.map((webcam, index) => {
      const label = !webcam?.label ? `Video ${index + 1}` : webcam.label;

      return { deviceId: webcam.deviceId, label: label };
    });
    setWebCamList(webCams);
  };

  const initMic = async () => {
    const getMics = await meeting?.getMics();
    const mics = getMics.map((mic, index) => {
      const label = !mic.label ? `Audio ${index + 1}` : mic.label;

      return { deviceId: mic.deviceId, label: label };
    });
    setMicList(mics);
  };

  const onParticipantJoined = participant => {
    // Change quality to low, med or high based on resolution
    participant && participant.setQuality('high');
  };
  const onMeetingJoined = async () => {
    console.log('onMeetingJoined');
    initMic();
    initCam();
    const { changeWebcam, disableWebcam, muteMic, unmuteMic } = mMeetingRef.current;
    console.log(mMeetingRef.current);
    await new Promise(resolve => {
      disableWebcam();
      muteMic();
      setTimeout(async () => {
        const micValue = getStorage('MicOn');
        const webCamValue = getStorage('WebCamOn');
        setVideoEnabled(webCamValue);
        setMicEnabled(micValue);

        const track = await createCameraVideoTrack({
          optimizationMode: 'motion',
          encoderConfig: 'h720p_w1280p',
          multiStream: false,
          facingMode: isMobile ? 'user' : 'environment'
        });
        videoRef.current = track;
        // videoDoctorRef.current = track;
        changeWebcam(track);
        unmuteMic();
        resolve();
      }, 500);
    });
  };

  const meeting = useMeeting({
    onMeetingJoined,
    onParticipantJoined
  });
  const [toggleView, setToggleView] = useState();

  const switchToChat = ({ micOn, webcamOn }) => {
    const isMobile = mobileBreakPoint();
    setShowFullChat(true);
    setShowVideo(false);
    setShowChat(false);
    setShowInfo(!isMobile);
    micOn && meeting.toggleMic();
    webcamOn && meeting.toggleWebcam();
    setIsUnread(false);
  };

  const setLayout = () => {
    let layoutView = 'vertical-view';
    if (meeting.participants.size === 1) {
      return layoutView;
    }

    if (toggleView) {
      layoutView = 'tile-view';
    }

    return layoutView;
  };

  const handleMicUpdate = () => {
    const hasSelectedDevice = micDeviceId ? micList.map(list => list.deviceId).includes(micDeviceId) : false;

    if (!hasSelectedDevice) {
      setMicDeviceId('default');
      meeting?.changeMic('default');
    } else {
      setMicDeviceId(micDeviceId);
      meeting?.changeMic(micDeviceId);
    }
  };

  useEffect(() => {
    mMeetingRef.current = meeting;
  }, [meeting]);

  useEffect(() => {
    initMic();
    initCam();
  }, []);

  useEffect(() => {
    navigator.mediaDevices.ondevicechange = () => {
      handleMicUpdate();
    };
  }, []);

  return (
    <>
      {chunk([...meeting.participants.keys()]).map(k => (
        <Fragment key={k}>
          <div className={`video-layout ${setLayout()} ${meeting.participants.size === 1 ? 'single' : ''}`}>
            {k.map(l => (
              <ParticipantView
                key={l}
                hasMoreParticipant={+meeting.participants.size > 1}
                participantId={l}
                videoRef={videoRef}
                participantType={participantType}
                leave={switchToChat}
                toggleMic={meeting.toggleMic}
                toggleWebcam={meeting.toggleWebcam}
                toggleView={toggleView}
                setToggleView={setToggleView}
                isScreenSharing={!!meeting.presenterId}
                webCamList={webCamList}
                micList={micList}
              />
            ))}
          </div>
        </Fragment>
      ))}
    </>
  );
};

export default ParticipantsView;
