import { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';

import Toast from 'components/Toast';

const csr = typeof window !== "undefined";
const useToast = () => {
  const [options, setOptions] = useState({});
  const containerRef = useRef();

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    const toastContainer = document.createElement("div");
    body.insertAdjacentElement("beforeend", toastContainer);

    containerRef.current = toastContainer;
  }, []);

  useEffect(() => {
    if (csr) {
      ReactDOM.render(
        Object.keys(options).length ? <Toast {...options} /> : null,
        containerRef.current
      );
    }
  }, [options]);

  const show = (options) => {
    setOptions({ ...options, onClose: () => destroy() });
  };

  const destroy = () => {
    setOptions({});
  };

  return { show, destroy };
};

export default useToast;
