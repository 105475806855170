import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: calc(100% - 60px);
`;

export const Content = styled.div`
  padding: 1rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 24px;
  text-align: center;
`;

export const CountdownSection = styled.div`
  margin-bottom: 30px;
`;
