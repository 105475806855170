import { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import ThankYouSection from './thank-you-section';
import FeedbackSection from './feedback-section';
import { useCSATContext } from 'libs/contexts/csat';
import { CSAT_STEPS as STEPS } from 'libs/constant';

const CSATModal = props => {
  const { show, toggle, consultationCompletedUrl } = props;
  const { step, getQuestions } = useCSATContext();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (show && !initialized) {
      getQuestions().then(() => {
        setInitialized(true);
      });
    }
  }, [show, initialized, getQuestions]);

  if (!initialized || step === STEPS.IDLE) return null;

  return (
    <Modal show={show} toggle={toggle} className="modal-full">
      {step === STEPS.THANK_YOU_SCREEN ? (
        <ThankYouSection consultationCompletedUrl={consultationCompletedUrl} />
      ) : (
        <FeedbackSection consultationCompletedUrl={consultationCompletedUrl} toggle={toggle} />
      )}
    </Modal>
  );
};

export default CSATModal;
