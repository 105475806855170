import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DrawerContentQueue,
  ContentQueueIcon,
  ContentQueueTitle,
  ContentQueueDescription,
  ContentQueueButton
} from './styles';
import useAction from './action';
import AppDrawer from '../app-drawer';
import { useEffect } from 'react';

const CloseDrawer = ({
  show,
  type = '',
  description = null,
  ignoreHideOnClickLeave = false,
  onHide = () => {},
  onClickLeave = () => {},
  onClickAccept = () => {},
  btnPrimaryText = null,
  customAction = {},
  iconContent = null
}) => {
  const { t } = useTranslation();

  const {
    modalContent,
    isShowDrawerQueue,
    isFetchingDelete,
    onShowDrawerQueue,
    onClickPrimaryButton,
    onClickSecondaryButton,
    onSetDrawerType
  } = useAction({
    description,
    ignoreHideOnClickLeave,
    onClickLeave,
    t,
    onHide,
    onClickAccept,
    btnPrimaryText,
    iconContent,
    customAction
  });

  useEffect(() => {
    if (show) {
      onShowDrawerQueue(show);
      onSetDrawerType(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, type]);

  return (
    <>
      <AppDrawer
        show={isShowDrawerQueue}
        content={
          <DrawerContentQueue>
            <ContentQueueIcon>{modalContent.icon}</ContentQueueIcon>
            <ContentQueueTitle>{t(modalContent.title)}</ContentQueueTitle>
            <ContentQueueDescription>{modalContent.description}</ContentQueueDescription>
            <ContentQueueButton disable={isFetchingDelete} onClick={onClickPrimaryButton} outline={false} block>
              {t(modalContent.primary_button)}
            </ContentQueueButton>
            <ContentQueueButton
              disable={isFetchingDelete}
              onClick={onClickSecondaryButton}
              variant="outline-green"
              block
            >
              {t(modalContent.secondary_button)}
            </ContentQueueButton>
          </DrawerContentQueue>
        }
        height="auto"
      />
    </>
  );
};

CloseDrawer.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  type: PropTypes.string,
  ignoreHideOnClickLeave: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClickLeave: PropTypes.func,
  customAction: PropTypes.shape({
    primary: PropTypes.func,
    secondary: PropTypes.func
  }),
  iconContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default CloseDrawer;
