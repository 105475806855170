// import { useFilePicker } from 'use-file-picker';
import React from 'react';
import { Paperclip } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';

const ImageUpload = ({ openFileSelector, filesContent, loading, errors, t }) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="chat__input-attachment-button">
      <Paperclip
        size={24}
        color={Colors.primary.NavyBlue}
        weight="light"
        style={{ cursor: 'pointer' }}
        onClick={() => openFileSelector()}
      />
    </div>
  );
};

export default ImageUpload;
