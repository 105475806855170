import styled from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const QueueContainter = styled.div`
  border-style: solid;
  border-width: 1px 0px;
  background-color: white;
  border-color: ${Colors.grayscale.LineGreyLight};
  padding: 14px 16px;
  display: flex;
`;

export const Queue = styled.div`
  background-color: ${Colors.grayscale.RhinoGrey};
  color: white;
  border-radius: 4px;
  margin: auto 0px;
  max-height: 52px;
  max-width: fit-content;
  padding: 4px;
  text-align: center;
`;

export const QueueNumber = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

export const TextWrapper = styled.div`
  margin: auto 0px;
  .mb-2 {
    margin-bottom: 4px;
  }
`;

export const ScheduleAppointment = styled.div`
  color: ${Colors.primary.NavyBlue};
  cursor: pointer;
  user-select: none;
`;
