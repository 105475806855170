import { useEffect, useState } from 'react';

const useAction = ({ isConsentOpen = false }) => {
  const [isDismiss, setIsDismiss] = useState(false);

  useEffect(() => {
    if (isConsentOpen) setIsDismiss(false);
  }, [isConsentOpen]);

  const onClickDismiss = () => setIsDismiss(true);

  return { isDismiss, onClickDismiss };
};

export default useAction;
