import { ToastContainer as TC, Toast as T } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import Colors from "okadoc-component-ui/lib/Colors";

export const ToastContainer = styled(TC)`
  position: fixed !important;
  z-index: 9999;
`;

const rotate = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const Wrap = styled.div`
  position: fixed;
  background-color: ${Colors.text.WhiteText};
  left: 50%;
  bottom: 5%;
  min-width: 300px;
  transform: translate(-50%,-5%);
  z-index: 9999;
  animation: ${rotate} 0.5s;
`;

export const ToastContent = styled.div`
  margin-left: 6px;
  margin-right: 6px;
`;

export const ToastClose = styled.div`
  cursor: pointer;
`;

export const ToastWrapper = styled(T)`
  // margin-bottom: 32px;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(67, 75, 81, 0.15);
  border-radius: 4px;

  &.toast {
    width: auto;
  }

  .toast-header {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    margin-left: -6px;
    margin-right: -6px;
    padding: 12px;
    font-size: 16px;
    font-family: "Nunito Sans";
    border: none;
    color: ${Colors.text.RhinoGrey};
  }

  &.bg-success {
    background-color: ${Colors.extended.SoftNiagara} !important;
  }

  &.bg-danger {
    background-color: ${Colors.feedback.SoftRed} !important;
  }
`;
