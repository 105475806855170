import React, { PureComponent } from 'react';
import Svg from '../components/Svg';

class Collapsible extends PureComponent {
  state = {
    open: false
  };

  togglePanel(e) {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { children, title } = this.props;
    return (
      <div className="list-group__item" onClick={e => this.togglePanel(e)}>
        <div className="d-flex justify-content-between align-items-center">
          <span className="list-group__title">{title}</span>
          <Svg
            external
            name="https://img.okadoc.com/photos/block_images/img/icon/ic-chevron-down.svg"
            alt="navigate"
            width="12px"
            style={{ transform: this.state.open ? '' : 'rotate(-90deg)' }}
          />
        </div>
        {this.state.open ? <div className="mt-15">{children}</div> : null}
      </div>
    );
  }
}

export default Collapsible;
