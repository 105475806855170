import styled, { createGlobalStyle } from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const IdlingDesktopModalGlobalStyle = createGlobalStyle`
  .bootstrap-modal.consent-modal.modal {
    .modal-body {
      padding: 24px 32px;
    }
    @media (min-width: 576px) {
      .modal-dialog {
        max-width: 468px !important;
      }
    }
  }
`;

export const IdlingDesktopIconContainer = styled.div`
  padding-bottom: 8px;
`;

export const IdlingDesktopTitleContainer = styled.div`
  h4 {
    color: ${Colors.text.RhinoDark};
    font-size: 18px;
    font-weight: 700;
  }
  padding-bottom: 16px;
`;

export const IdlingDesktopDescriptionContainer = styled.div`
  color: ${Colors.text.RhinoGrey};
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 24px;
  position: relative;
  text-align: center;
`;

export const IdlingDesktopActionContainer = styled.div`
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
  padding-bottom: 32px;
`;

export const IdlingDesktopActionItem = styled.div`
  flex: 1 1 auto;
  padding-left: 8px;
  padding-right: 8px;
`;
