import PropTypes from 'prop-types';
import ConsentDesktopScreen from './desktop';
import ConsentMobileScreen from './mobile';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';

const ConsentModal = ({ open = false, consent = {}, onHide = () => {}, onSubmit = () => {} }) => {
  const isMobile = mobileBreakPoint();

  if (isMobile) return <ConsentMobileScreen open={open} consent={consent} onHide={onHide} onSubmit={onSubmit} />;
  return <ConsentDesktopScreen open={open} consent={consent} onHide={onHide} onSubmit={onSubmit} />;
};

ConsentModal.propTypes = {
  open: PropTypes.bool,
  consent: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ConsentModal;
