import React from 'react';
import Modal from './Modal';
import Png from './Png';

class ModalPhoto extends React.PureComponent {
  render() {
    const { show, closeModal, photo } = this.props;
    return photo ? (
      <Modal show={show} toggle={closeModal} className="image-modal" withCloseButton>
        <div className="image-modal__content">
          <Png name={photo.text} external className="img-responsive img-attach-preview" alt="attachment" />
        </div>
      </Modal>
    ) : null;
  }
}

export default ModalPhoto;
