import runtimeConfig from 'libs/runtime-config';

const DEFAULT_CONFIG = { mode: 'audio' };

const getRecordingConfig = () => {
  try {
    return runtimeConfig?.REACT_APP_OKA_PUBLIC_RECORDING_CONFIG
      ? JSON.parse(runtimeConfig.REACT_APP_OKA_PUBLIC_RECORDING_CONFIG)
      : DEFAULT_CONFIG;
  } catch (error) {
    return DEFAULT_CONFIG;
  }
};

export default getRecordingConfig;
