import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CloseDrawer from 'components/molecules/close-drawer';
import { DRAWER_TYPE } from 'containers/video/chat-view/constants';
import DocumentPreviewTray from 'components/molecules/document-preview-tray';
import useAction from 'components/organisms/consent-modal/actions';
import { ConsentFileName, ConsentError } from 'components/organisms/consent-modal/styles';

const ConsentMobileScreen = ({
  open = false,
  consent: { fileName = '', description = '', url = '' },
  onHide = () => {},
  onSubmit = () => {}
}) => {
  const [isShowPreview, setIsShowPreview] = useState(false);

  const { isDismiss, onClickDismiss } = useAction({ isConsentOpen: open });
  const { t } = useTranslation();

  const onHandlePreview = () => setIsShowPreview(prevState => !prevState);

  const formatDescription = description.replace(/\d+/, val => {
    return `<span class="text-blue">${val}</span>`;
  });

  return (
    <>
      <CloseDrawer
        show={open}
        description={
          <>
            {t('To proceed, please review and accept')}{' '}
            <ConsentFileName onClick={onHandlePreview}>{fileName}</ConsentFileName>.{' '}
            <span dangerouslySetInnerHTML={{ __html: formatDescription }} />
            <ConsentError $isDesktop $isHide={!isDismiss}>
              {t('You must accept to proceed')}
            </ConsentError>
          </>
        }
        type={DRAWER_TYPE.CONSENT_MODAL}
        ignoreHideOnClickLeave
        onClickAccept={onSubmit}
        onHide={onHide}
        onClickLeave={onClickDismiss}
      />
      <DocumentPreviewTray file={{ fileName: fileName, url }} show={isShowPreview} onHide={onHandlePreview} />
    </>
  );
};

ConsentMobileScreen.propTypes = {
  open: PropTypes.bool,
  consent: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ConsentMobileScreen;
