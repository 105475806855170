import styled, { createGlobalStyle, css } from 'styled-components';

export const PreMeetingGlobalStyle = createGlobalStyle`
  ${props =>
    props.isODDConsultation &&
    !props.isDoctor &&
    css`
      .video-embed {
        &.active {
          @media (max-width: 768px) {
            height: auto;
          }
        }
      }
      .premeeting-screen {
        @media (max-width: 768px) {
          display: block;
          flex: 1;
          padding: 16px;
          position: relative;
        }

        .content {
          @media (min-width: 768px) {
            padding-bottom: 0;
          }

          .title {
            margin-bottom: 24px;

            @media (max-width: 768px) {
              font-size: 21px;
              line-height: 29px;
            }
          }
        }

        &-preview {
          &-container {
            #preview {
              @media (max-width: 768px) {
                max-width: 192px;
                min-height: 332px;
                min-width: 192px;
              }

              .video-pre-meeting {
                @media (max-width: 768px) {
                  border-radius: 12px;
                  height: 332px;
                }
              }

              .preview-caption-text {
                @media (max-width: 768px) {
                  background: none;
                }
              }

              &-navigation {
                @media (max-width: 768px) {
                  margin-left: 0;
                  margin-right: 0;
                  margin-top: 60px;
                  width: 100%;
                }

                .prejoin-button-container {
                  @media (max-width: 768px) {
                    width: 100%;
                  }

                  .action-btn {
                    @media (max-width: 768px) {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }

        .title-extra {
          color: #767b82;
          font-size: 14px;
          margin-bottom: 32px;
          text-align: center;
        }
      }
    `}
`;

export const VideoPreview = styled.div`
  min-width: 320px;

  @media (max-width: 767px) {
    max-width: 320px;
  }
`;
