/* eslint-disable valid-jsdoc */
import Cookies from 'universal-cookie';
import { Base64 } from 'js-base64';

const CookiesHandler = new Cookies();
const secure = process.env.COOKIE_SECURE === 'true' || process.env.REACT_APP_COOKIE_SECURE === 'true';
const tKey = `${process.env.REACT_APP_TOKEN_CACHE}${process.env.REACT_APP_COOKIE_SUFFIX || ''}`;

const getDomain = () => {
  const hostname = typeof window !== 'undefined' && window.location.hostname;
  const arrHostname = hostname && hostname.split('.');

  // if length of hostname is 1 then set domain into localhost
  return arrHostname.length > 1
    ? `.${arrHostname.filter((val, index) => index >= arrHostname.length - 2).join('.')}`
    : hostname;
};

/**
 *
 * @param {*} name cookie name
 * @param {*} context nextjs context || (Express request object)
 * @param {*} expireIn cookie will expire in n-time, parameter time in seconds.
 */
export const setCookie = (name, value, expireIn = 86400) => {
  const domain = getDomain();
  CookiesHandler.set(name, value, {
    path: '/',
    maxAge: expireIn,
    secure,
    domain
  });
};

/**
 *
 * @param {*} name cookie name
 * @param {*} context nextjs context || (Express request object)
 */
export const destroyCookie = name => {
  const domain = getDomain();
  CookiesHandler.destroy(name, {
    path: '/',
    domain
  });
};

export const getAuthInfo = (cookieHeaders = false) => {
  let authInfo = { token: false, refreshToken: false, email: '', gender: '' };
  try {
    // querying auth info ...
    let cHandler = null;
    if (cookieHeaders) cHandler = new Cookies(cookieHeaders);
    else cHandler = new Cookies();

    const info = cHandler.get(tKey);
    authInfo = {
      ...info // optional
    };
    authInfo.token = info?.tK || false;
    authInfo.refreshToken = info?.rT || false;
    authInfo.email = info?.email || false;
    const p = info?.phone || '';
    authInfo.phone = Base64.decode(p);
    authInfo.timezone = cHandler.get('timezone');
  } catch (error) {
    console.log('Failed in getting auth info: ', error);
  }
  return authInfo;
};

/**
 *
 * @param {*} context nextjs context || (Express request object)
 * @return all cookies
 */
export const getCookies = () => {
  return CookiesHandler.getAll();
};

export const getToken = (isDoctor = false) => {
  if (isDoctor) {
    const tokenProOkadoc = CookiesHandler.get('__Ok4DWtck_testing');

    return tokenProOkadoc;
  }
  const { token } = getAuthInfo();
  return token;
};

export const getCookiesByKey = (key = null) => {
  if (!key) throw new Error('Key storage is required');

  const value = CookiesHandler.get(key);
  return value;
};
