import { useMeeting, usePubSub } from '@videosdk.live/react-sdk';
import { useEffect, useRef } from 'react';

import useChat from 'libs/hooks/useChat';
import { CONSULTATION_STATUS, EVENT_PUBSUB } from 'libs/constant';
import MeetingContainer from './meeting-container';
import { getFullDatetime } from 'libs/date';
import getRecordingConfig from 'libs/recording-config';

const recordingConfig = getRecordingConfig();

const Meeting = ({
  setShowVideo,
  setShowChat,
  setShowInfo,
  setShowFullChat,
  showVideo,
  showChat,
  showInfo,
  showFullChat,
  participantType,
  country,
  lang,
  isPatient,
  setIsUnread,
  wlVersion,
  isXToken,
  t
}) => {
  const _mounted = useRef();
  const {
    value: { telemedicine, isDoctor, doctor, patient, appointment },
    action
  } = useChat();
  const { publish } = usePubSub(EVENT_PUBSUB.PARTICIPANTS_STATUS);
  const { publish: publishChat } = usePubSub(EVENT_PUBSUB.CHAT);
  const shallRecord = telemedicine?.shallrecorded;
  const handleSendEvent = (isDoctorFlag, status) => {
    const messageToSent = {
      message: {
        status,
        isDoctor: isDoctorFlag
      },
      type: 'message'
    };
    publish(JSON.stringify(messageToSent), { persist: true });
  };

  const { startRecording, meetingId, isMeetingJoined, isRecording } = useMeeting({
    onParticipantLeft: participant => {
      const isEventFromDoctor = !!participant?.id?.includes('doctor');

      handleSendEvent(isEventFromDoctor, CONSULTATION_STATUS.YET_TO_JOIN);
    },
    onParticipantJoined: participant => {
      const isEventFromDoctor = participant.id.includes('doctor');

      handleSendEvent(isEventFromDoctor, CONSULTATION_STATUS.IN_CONSULTATION);
    },
    onError: err => {
      console.log('[LOG] useMeeting err', err);
    }
  });

  /* This is a hook that will be called `video_end` when the user closed the tab. */
  useEffect(() => {
    const handleLogUserLeave = () => {
      try {
        const remoteUser = !isDoctor ? doctor : patient;
        const userName = isDoctor ? remoteUser?.title : [remoteUser?.first_name, remoteUser?.last_name].join(' ');
        const format = !showFullChat ? 'video_end' : 'chat_end';
        const eventEndCall = {
          ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
          format,
          message: format,
          text: format,
          time: getFullDatetime,
          sender: !isDoctor ? 'patient' : 'doctor'
        };
        const eventPayload = JSON.stringify(eventEndCall);

        action.saveChat({
          appointmentNumber: appointment.appointmentNumber,
          message: eventEndCall
        });
        publishChat(eventPayload, { persist: false });
      } catch (err) {
        console.error('Error -> ', err);
      }
    };

    window.addEventListener('beforeunload', handleLogUserLeave);

    return () => {
      window.removeEventListener('beforeunload', handleLogUserLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meetingId && isMeetingJoined && shallRecord && !isRecording && !showFullChat) {
      startRecording(null, null, recordingConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId, isMeetingJoined, shallRecord, showFullChat]);

  /* This is a hook that will be called `video_join` when the user joined the meeting. */
  useEffect(() => {
    if (
      (isMeetingJoined && !_mounted.current) ||
      (isMeetingJoined && _mounted.current && !isDoctor && appointment?.isODDConsultation)
    ) {
      try {
        const currentUser = isDoctor ? doctor : patient;
        const userName = isDoctor ? currentUser?.title : [currentUser?.first_name, currentUser?.last_name].join(' ');

        const eventJoinCall = {
          ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
          appointmentNumber: appointment?.appointmentNumber,
          format: showFullChat ? 'chat_start' : 'video_join',
          message: `${userName}`,
          text: `${userName}`,
          time: new Date().toISOString(),
          sender: !isDoctor ? 'patient' : 'doctor'
        };

        publishChat(JSON.stringify(eventJoinCall), { persist: false });
        action.saveChat({
          appointmentNumber: appointment?.appointmentNumber,
          message: eventJoinCall
        });

        _mounted.current = true;
      } catch (err) {
        console.error('Error -> ', err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMeetingJoined, isDoctor, showFullChat, showVideo, appointment, _mounted]);

  return (
    <MeetingContainer
      participantType={participantType}
      showVideo={showVideo}
      showChat={showChat}
      showInfo={showInfo}
      setShowVideo={setShowVideo}
      setShowChat={setShowChat}
      setShowInfo={setShowInfo}
      setShowFullChat={setShowFullChat}
      showFullChat={showFullChat}
      isPatient={isPatient}
      country={country}
      lang={lang}
      isXToken={isXToken}
      setIsUnread={setIsUnread}
      t={t}
      wlVersion={wlVersion}
    />
  );
};

export default Meeting;
