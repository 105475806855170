import to from '../../await-to';
import { log } from '../../domain';
import OkaAxios from '../../axios';

export default class LocaleAPI {
  static GetInitServiceURL = async clientId => {
    return await to(
      OkaAxios.locale.get('/appservice/init', {
        headers: { 'client-id': clientId ?? process.env.REACT_APP_CLIENT_ID }
      })
    );
  };

  static GetMasterDocumentTags = async () => {
    return await to(OkaAxios.locale.get('/master/document-tags'));
  };

  static getDefaultHeader = (token, country = 'ae', lang = 'en') => {
    const customHeader = {};

    if (token) {
      customHeader['Authorization'] = `Bearer ${token}`;
    }
    if (country) customHeader['Country-ID'] = country;
    if (lang) customHeader['Accept-Language'] = lang;

    return customHeader;
  };

  static GetLanguage = async (country, lang, token, options) => {
    log(
      'getLanguage',
      JSON.stringify({
        country,
        lang,
        token,
        options
      })
    );

    const [err, resp] = await to(
      OkaAxios.locale.get(`/translation`, {
        params: { lang },
        headers: { 'Accept-Language': lang }
      })
    );

    if (err) {
      throw err.response.data;
    }
    log(
      'getLanguage: result',
      JSON.stringify({
        resp
      })
    );

    return resp.data;
  };
}
