import PropTypes from 'prop-types';
import Colors from 'okadoc-component-ui/lib/Colors';
import { X } from 'phosphor-react';
import Tray from 'components/atoms/tray';
import { AppDrawerGlobalStyle, AppDrawerHeader, AppDrawerHeaderTitle } from './styles';

const AppDrawer = ({ show = false, title = '', content = null, height, onHide = () => {}, ...props }) => {
  return (
    <>
      <AppDrawerGlobalStyle height={height} />
      <Tray show={show} className="app-drawer" placement="bottom" onHide={onHide} {...props}>
        {title && (
          <AppDrawerHeader>
            <X
              color={Colors.grayscale.RhinoGrey}
              size={24}
              weight="light"
              style={{ position: 'absolute', top: 16 }}
              onClick={onHide}
            />
            <AppDrawerHeaderTitle>{title}</AppDrawerHeaderTitle>
          </AppDrawerHeader>
        )}
        {content && <Tray.Body>{content}</Tray.Body>}
      </Tray>
    </>
  );
};

AppDrawer.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.node.isRequired,
  height: PropTypes.string,
  onHide: PropTypes.func
};

export default AppDrawer;
