/* eslint-disable no-undefined */
const awaitTo = async PromiseRequest => {
  try {
    const response = await PromiseRequest;

    return [null, response];
  } catch (err) {
    return [err, undefined];
  }
};

export default awaitTo;
