import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { init as initApm } from '@elastic/apm-rum';
import ChatProvider from 'libs/contexts/chats';
import ODDSSEProvider from 'libs/contexts/odd';
import CSATProvider from 'libs/contexts/csat';
import Main from './containers/main';

const isDevelopment = process.env.NODE_ENV === 'development';
if (!isDevelopment) {
  initApm({
    serviceName: process.env.REACT_APP_TRACKING_SERVICE_NAME,
    serverUrl: process.env.REACT_APP_TRACKING_APM_SERVER_URL,
    environment: process.env.REACT_APP_TRACKING_ENVIRONMENT
  });
}

function App() {
  return (
    <Router>
      <ChatProvider>
        <CSATProvider>
          <ODDSSEProvider>
            <Routes>
              <Route exact path="/" element={<Main />} />
            </Routes>
          </ODDSSEProvider>
        </CSATProvider>
      </ChatProvider>
    </Router>
  );
}

export default withTranslation('common')(App);
