import React, { useMemo, useState } from 'react';
import ModalPhoto from 'components/ModalPhoto';
import useChat from 'libs/hooks/useChat';
import { useTranslation } from 'react-i18next';
import MessageList, {
  ConsultationPrivacy,
  ConsultationLoading,
  SystemMessage,
  ConsultationODDNotice
} from './message-list';
import { CHAT_SYSTEM_MESSAGE, FORMATS_MESSAGE } from 'libs/constant';

const ChatViewReadOnly = ({ isLoadingChatHistory, messages, isPatient }) => {
  const { t } = useTranslation();
  const {
    value: { appointment, doctor, patient }
  } = useChat();
  const [photo, setPhoto] = useState();
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);

  const handleCloseModal = () => {
    setPhoto(null);
    setIsPhotoModalOpen(false);
  };

  const handleOpenPhoto = photo => {
    setPhoto(photo);
    setIsPhotoModalOpen(true);
  };

  const isSessionEnd = useMemo(() => {
    return messages?.filter(item => item.format === FORMATS_MESSAGE.SESSION_END)?.length > 0;
  }, [messages]);

  const showODDPatientContent = isPatient && appointment?.isODDConsultation;

  return (
    <>
      <div className="chat__log">
        <div className="container">
          {isLoadingChatHistory && <ConsultationLoading t={t} />}
          {isPatient && <ConsultationPrivacy t={t} />}
          {showODDPatientContent && <ConsultationODDNotice t={t} />}
          {showODDPatientContent && <SystemMessage message={CHAT_SYSTEM_MESSAGE.WAITING_ON_PATIENT} />}
          <MessageList
            appointment={appointment}
            isPatient={isPatient}
            messages={messages}
            doctorPicture={doctor?.profilePicture}
            patientPicture={patient?.profilePicture ?? patient?.photo}
            onOpenPhoto={handleOpenPhoto}
            t={t}
          />
          {showODDPatientContent && isSessionEnd && (
            <SystemMessage message={CHAT_SYSTEM_MESSAGE.COMPLETED_ON_PATIENT} />
          )}
        </div>
      </div>
      <ModalPhoto photo={photo} show={isPhotoModalOpen} closeModal={handleCloseModal} />
    </>
  );
};

export default ChatViewReadOnly;
