import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';

const useCountdown = ({ startTime, endTime, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState({});
  const timer = useRef(null);
  const start = moment(startTime);
  const end = moment(endTime);
  const diffTime = end.diff(start);

  const handleCompleteTime = useCallback(() => typeof onComplete === 'function' && onComplete(), [onComplete]);

  const stopCountdown = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  useEffect(() => {
    if (startTime && endTime) {
      timer.current = setInterval(() => {
        const currTime = moment();
        const newDiffTime = moment(startTime).diff(currTime);
        const duration = moment.duration(newDiffTime);

        if (duration.asSeconds() > 60 * 60) {
          setTimeLeft({
            timeDiff: duration,
            timeLeft: {
              days: duration.days(),
              hour: duration.hours(),
              minute: duration.minutes(),
              seconds: duration.seconds()
            },
            totalInMinutes: duration.asMinutes(),
            totalInSeconds: duration.asSeconds()
          });

          return;
        }

        if (duration.asSeconds() < 60 * 60) {
          stopCountdown();
          handleCompleteTime();

          return;
        }

        if (duration.asSeconds() <= 0) {
          stopCountdown();
          handleCompleteTime();

          return;
        }
      }, 1000);

      return () => clearInterval(timer.current);
    }
  }, [diffTime, startTime, endTime, handleCompleteTime]);

  return timeLeft;
};

export default useCountdown;
