import PropTypes from 'prop-types';
import { Info } from 'phosphor-react';
import Button from 'okadoc-component-ui/lib/Button';
import { useTranslation } from 'react-i18next';
import Colors from 'okadoc-component-ui/lib/Colors';
import BootstrapModal from 'components/atoms/bootstrap-modal';
import {
  IdlingDesktopModalGlobalStyle,
  IdlingDesktopIconContainer,
  IdlingDesktopTitleContainer,
  IdlingDesktopDescriptionContainer,
  IdlingDesktopActionContainer,
  IdlingDesktopActionItem
} from './styles';

const IdlingDesktopModal = ({
  show = false,
  description,
  btnPrimaryText,
  onClickPrimaryButton,
  onClickSecondaryButton,
  iconContent
}) => {
  const { t } = useTranslation();

  return (
    <>
      <IdlingDesktopModalGlobalStyle />
      <BootstrapModal className="consent-modal" show={show} centered>
        <BootstrapModal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <IdlingDesktopIconContainer>
              {iconContent ? iconContent : <Info color={Colors.primary.NavyBlue} size={48} weight="fill" />}
            </IdlingDesktopIconContainer>
            <IdlingDesktopTitleContainer>
              <h4>{t('Are you still there?')}</h4>
            </IdlingDesktopTitleContainer>
            <IdlingDesktopDescriptionContainer>{t(description)}</IdlingDesktopDescriptionContainer>
          </div>
          <IdlingDesktopActionContainer>
            <IdlingDesktopActionItem>
              <Button variant="outline-green" onClick={onClickSecondaryButton} block>
                {t('Stay in the call')}
              </Button>
            </IdlingDesktopActionItem>
            <IdlingDesktopActionItem>
              <Button onClick={onClickPrimaryButton} block>
                {t(btnPrimaryText)}
              </Button>
            </IdlingDesktopActionItem>
          </IdlingDesktopActionContainer>
        </BootstrapModal.Body>
      </BootstrapModal>
    </>
  );
};

IdlingDesktopModal.propTypes = {
  show: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  btnPrimaryText: PropTypes.string.isRequired,
  onClickSecondaryButton: PropTypes.func.isRequired,
  onClickPrimaryButton: PropTypes.func.isRequired,
  iconContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default IdlingDesktopModal;
