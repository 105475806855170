import { useState, useMemo } from 'react';
import { useGlobalConfigStore } from 'zustandStore';
import AppointmentAPI from 'libs/api/appointment';
import useChat from 'libs/hooks/useChat';
import useToast from 'libs/hooks/useToast';
import { Warning, Info } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';
import { getQueryParams } from 'libs/url';
import { sendMessage } from 'libs/message';
import { EVENT_TYPE_IFRAME, EVENT_PAGE_NAVIGATION_ITEM } from 'libs/constant';
import { DRAWER_TYPE } from 'containers/video/chat-view/constants';

const useAction = ({
  t,
  description,
  ignoreHideOnClickLeave,
  onClickAccept,
  onClickLeave,
  onHide,
  iconContent,
  btnPrimaryText,
  customAction
}) => {
  const [isShowDrawerQueue, setIsShowDrawerQueue] = useState(false);
  const [drawerType, setDrawerType] = useState(DRAWER_TYPE.SCHEDULE_APPOINTMENT);
  const [isFetchingDelete, setIsFetchingDelete] = useState(false);

  const isPatientJoinTheCall = useGlobalConfigStore(state => state.isPatientJoinTheCall);
  const { show } = useToast();
  const {
    value: { appointment }
  } = useChat();

  const params = getQueryParams(window.location);
  const domain = params?.domain;

  const onClickPrimaryButton = async e => {
    // enable to use custom action when clicking btn-primary
    if (typeof customAction?.primary === 'function') {
      customAction.primary();
      return;
    }

    if ([DRAWER_TYPE.WAITING_APPOINTMENT, DRAWER_TYPE.SCHEDULE_APPOINTMENT].includes(drawerType)) {
      await cancelAppointment(e);
    } else if (
      [DRAWER_TYPE.CONSENT_MODAL, DRAWER_TYPE.IDLING_MODAL].includes(drawerType) &&
      typeof onClickAccept === 'function'
    ) {
      onClickAccept();
      closeDrawer();
    } else if ([DRAWER_TYPE.CLOSE_APPOINTMENT].includes(drawerType)) {
      closeDrawer();
    }
  };

  const onClickSecondaryButton = () => {
    // enable to use custom action when clicking btn-secondary
    if (typeof customAction?.secondary === 'function') {
      customAction.secondary();
      return;
    }

    if (isPatientJoinTheCall || ignoreHideOnClickLeave) {
      onClickLeave?.();
    }
    if (!ignoreHideOnClickLeave) {
      closeDrawer();
    }
  };

  const onSetDrawerType = value => {
    setDrawerType(prev => value ?? prev);
  };

  const onShowDrawerQueue = value => {
    setIsShowDrawerQueue(value);
  };

  const cancelAppointment = async e => {
    e?.preventDefault();

    setIsFetchingDelete(true);
    // eslint-disable-next-line new-cap
    const [err, response] = await AppointmentAPI.PutCancelAppointment(appointment.appointmentNumber);

    if (err) {
      show({
        type: 'danger',
        message: t('Something went wrong, please try again later')
      });
    }
    if (response) {
      sendMessage({
        message: {
          type: EVENT_TYPE_IFRAME.PAGE_NAVIGATION,
          data: {
            type: EVENT_PAGE_NAVIGATION_ITEM.SEARCH_PAGE
          }
        },
        domain
      });
    }
    setIsFetchingDelete(false);
  };

  const closeDrawer = () => {
    onShowDrawerQueue(false);
    onHide();
  };

  const modalContent = useMemo(() => {
    switch (drawerType) {
      case DRAWER_TYPE.WAITING_APPOINTMENT:
        return {
          icon: <Icon type="info" />,
          title: 'Demand is High',
          description: t(
            'Waiting time is currently higher than normal, please consider booking a scheduled appointment for a time that’s convenient for you.'
          ),
          primary_button: 'Book Scheduled Appointment',
          secondary_button: 'Continue Waiting'
        };
      case DRAWER_TYPE.CLOSE_APPOINTMENT:
        return {
          icon: <Icon />,
          title: 'Active Consultation',
          description: t('The consultation is ongoing. Are you sure that you want to leave?'),
          primary_button: 'Back to Consultation',
          secondary_button: 'Leave now'
        };
      case DRAWER_TYPE.CONSENT_MODAL:
        return {
          icon: <Icon type="info" />,
          title: 'Telemedicine Consent',
          description,
          primary_button: 'I Accept',
          secondary_button: 'Dismiss'
        };
      case DRAWER_TYPE.IDLING_MODAL:
        return {
          icon: iconContent,
          title: 'Are you still there?',
          description: t(description),
          primary_button: btnPrimaryText,
          secondary_button: 'Stay in the call'
        };
      default:
        return {
          icon: <Icon />,
          title: 'Currently in Queue',
          description: t(
            'You are currently in the queue to consult a doctor. Would you like to leave the queue and schedule an appointment instead?'
          ),
          primary_button: 'Book Scheduled Appointment',
          secondary_button: 'Go Back'
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerType, description, btnPrimaryText, iconContent]);

  return {
    modalContent,
    isShowDrawerQueue,
    drawerType,
    isFetchingDelete,
    onClickPrimaryButton,
    onClickSecondaryButton,
    onShowDrawerQueue,
    onSetDrawerType,
    closeDrawer
  };
};

const Icon = ({ type = 'warning' }) => {
  let element = <Warning color={Colors.feedback.Tangerine} size={48} weight="fill" />;

  if (type === 'info') {
    element = <Info color={Colors.primary.NavyBlue} size={48} weight="fill" />;
  }
  return element;
};

export default useAction;
