import PropTypes from 'prop-types';
import Left from './left';
import Right from './right';
import Text from './text';

const Pagination = ({ children = null }) => <div className="react-pdf__pagination">{children}</div>;

Pagination.propTypes = {
  children: PropTypes.node
};

Pagination.Left = Left;
Pagination.Right = Right;
Pagination.Text = Text;

export default Pagination;
