import React, { useRef, useEffect, useState } from 'react';
import { usePubSub, useMeeting } from '@videosdk.live/react-sdk';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useChat from 'libs/hooks/useChat';
import Button from 'components/Button';
import Png from 'components/Png';
import AppDrawer from 'components/molecules/app-drawer';
import { useGlobalConfigStore } from 'zustandStore';
import { EVENT_PUBSUB } from 'libs/constant';
import notificationSound from 'assets/audio/notification-sound.mp3';
import Left from './left';
import CloseDrawer from 'components/molecules/close-drawer';
import { getFullDatetime } from 'libs/date';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import { sendMessage } from 'libs/message';
import { DRAWER_TYPE } from 'containers/video/chat-view/constants';

const Header = ({
  showHeader,
  showVideo,
  setShowVideo,
  showToggle,
  showChat,
  setShowChat,
  showInfo,
  setShowInfo,
  showFullChat,
  setShowFullChat,
  showBackButton,
  isSessionEnd,
  recipientName,
  recipientPhoto,
  preMeetingScreen = null,
  setIsUnread,
  isUnread,
  isMeetingStarted,
  t
}) => {
  const {
    value: {
      appointment: { appointmentNumber, isODDConsultation },
      doctor,
      patient,
      isDoctor,
      isShowAppDrawer
    },
    action: { saveChat, handleShowAppDrawer }
  } = useChat();

  const { meeting, toggleMic, toggleWebcam, localWebcamOn, localMicOn } = useMeeting();
  const webcamOn = useGlobalConfigStore(state => state.webcamOn);
  const micOn = useGlobalConfigStore(state => state.micOn);
  const leavedScreenStates = useGlobalConfigStore(state => state.leavedScreenStates);
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const isPatientJoinTheCall = useGlobalConfigStore(state => state.isPatientJoinTheCall);
  const audioPlayer = useRef(null);
  const meetingSdk = useMeeting();

  const [toggleCamera, setToggleCamera] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(DRAWER_TYPE.SCHEDULE_APPOINTMENT);

  const playAudio = () => {
    audioPlayer.current.play();
  };
  const { publish, messages } = usePubSub(EVENT_PUBSUB.CHAT, {
    onMessageReceived(message) {
      try {
        const parsedMessage = JSON.parse(message.message);
        const isParticipantDoctor = parsedMessage.sender.includes('doctor');
        if ((isDoctor && !isParticipantDoctor) || (!isDoctor && isParticipantDoctor)) {
          playAudio();
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  });
  const showSideBar = (showVideo && showChat) || showInfo;
  const photo = recipientPhoto && !isDoctor ? recipientPhoto : 'doctor-profile-default';
  const handleClickVideo = () => {
    // toggle video afer the participant hang up the meeting previously
    if (!showVideo && leavedScreenStates) {
      // toggle the webcam to the latest its state
      if ((leavedScreenStates?.webcamOn && !localWebcamOn) || (!leavedScreenStates?.webcamOn && localWebcamOn)) {
        toggleWebcam();
      }
      // toggle the mic to the latest its state
      if ((leavedScreenStates?.micOn && !localMicOn) || (!leavedScreenStates?.micOn && localMicOn)) {
        toggleMic();
      }

      toggleState({
        webcamOn: leavedScreenStates?.webcamOn || webcamOn,
        micOn: leavedScreenStates?.micOn || micOn,
        leavedScreenStates: null
      });
    }

    if (!isDoctor && isODDConsultation) {
      handleShowAppDrawer({
        checkActive: true,
        callback: handleShowVideo
      });
    } else {
      handleShowVideo();
    }
  };

  const handleClickInfo = () => {
    setShowInfo(prevCheck => !prevCheck);
    setShowChat(false);
  };

  const handleClickHide = () => {
    setShowInfo(false);
  };

  const handleShowVideo = () => {
    setShowVideo(true);
    setShowFullChat(false);
    setShowChat(false);
  };

  const handleSwitchCamera = () => {
    setToggleCamera(!toggleCamera);
    meetingSdk?.changeWebcam();
  };

  const onClickLeave = () =>
    new Promise(resolve => {
      const remoteUser = !isDoctor ? doctor : patient;
      const userName = isDoctor ? remoteUser?.title : [remoteUser?.first_name, remoteUser?.last_name].join(' ');

      const eventEndCall = {
        ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
        format: 'video_end',
        message: 'video_end',
        text: 'video_end',
        time: getFullDatetime,
        sender: !isDoctor ? 'patient' : 'doctor'
      };
      const eventPayload = JSON.stringify(eventEndCall);

      saveChat({
        appointmentNumber: appointmentNumber,
        message: eventEndCall
      });
      publish(eventPayload, { persist: false });

      // keep the previous state
      toggleState({
        leavedScreenStates: { webcamOn, micOn }
      });

      const leave = ({ micOn, webcamOn }) => {
        const isMobile = mobileBreakPoint();
        setShowFullChat(true);
        setShowVideo(false);
        setShowChat(false);
        setShowInfo(!isMobile);
        micOn && meeting.toggleMic();
        webcamOn && meeting.toggleWebcam();
        setIsUnread(false);
      };

      leave({
        webcamOn,
        micOn
      });

      setShowAlert(false);
      sendMessage({ message: { type: 'backSession' } });

      // prevents spams click leave now
      setTimeout(() => {
        resolve(true);
      }, 200);
    });

  useEffect(() => {
    setIsUnread(!showChat && !showFullChat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    if (!showVideo && localWebcamOn) {
      toggleWebcam();
    }
    if (!showVideo && localMicOn) {
      toggleMic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showVideo]);

  return (
    <div className={classNames('chatbox-header-group', { 'chatbox-header-hide': !showHeader })}>
      <audio ref={audioPlayer} src={notificationSound} />
      <Left
        isODDConsultation={isODDConsultation}
        showVideo={showVideo}
        setShowVideo={setShowVideo}
        showBackButton={showBackButton}
        isSessionEnd={isSessionEnd}
        showToggle={showToggle}
        showChat={showChat}
        setShowChat={setShowChat}
        showInfo={showInfo}
        setShowInfo={setShowInfo}
        showFullChat={showFullChat}
        setShowFullChat={setShowFullChat}
        handleClickVideo={handleClickVideo}
        isUnread={isUnread}
        setIsUnread={setIsUnread}
        isMeetingStarted={isMeetingStarted}
        handleSwitchCamera={handleSwitchCamera}
        setShowAlert={val => setShowAlert(val)}
        setAlertType={val => setAlertType(val)}
        t={t}
      />
      {showSideBar && (
        <div className="chatbox-header chatbox-header--sidebar">
          {showChat && showVideo && (
            <>
              <div className="chatbox-header-menu__info">
                <div className="thumbnail">
                  <Png
                    external={!!recipientPhoto && !isDoctor}
                    name={photo}
                    alt="doctor profile"
                    className="thumbnail__image"
                    width="10px"
                  />
                </div>
                <div className="chatbox-header-menu__info-text">
                  <h6 className="text-truncate">{recipientName}</h6>
                </div>
              </div>
              <div className="chatbox-header-menu__mobile">
                <div className="chatbox-header-menu__mobile-close">
                  <Button
                    onClick={() => {
                      setShowAlert(true);
                      if (isPatientJoinTheCall) {
                        setAlertType(DRAWER_TYPE.CLOSE_APPOINTMENT);
                      } else {
                        setAlertType(DRAWER_TYPE.SCHEDULE_APPOINTMENT);
                      }
                    }}
                    variant="close"
                  ></Button>
                </div>
                <div className="chatbox-header-menu__mobile-info text-bold">{t('Chat')}</div>
                <div className="chatbox-header-menu__mobile-button">
                  <Button disabled={isSessionEnd} onClick={handleClickVideo} variant="video" active={showVideo} />
                  <Button disabled={isSessionEnd} onClick={handleClickInfo} variant="info" active={showInfo} />
                </div>
              </div>
            </>
          )}
          {showInfo && (
            <div className="chatbox-header-menu__mobile">
              <div className="d-flex justify-content-end w-100">
                <button disabled={isSessionEnd} className="button clear-blue" onClick={handleClickHide}>
                  <span className="text-blue" style={{ fontSize: 14, fontWeight: 600 }}>
                    {t('Hide')}
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <AppDrawer
        show={isShowAppDrawer}
        title={t('Video Preview')}
        content={preMeetingScreen}
        height="100vh"
        onHide={() => handleShowAppDrawer({ checkActive: false })}
      />
      <CloseDrawer show={showAlert} onHide={() => setShowAlert(false)} type={alertType} onClickLeave={onClickLeave} />
    </div>
  );
};

Header.propTypes = {
  showSideBar: PropTypes.bool,
  showHeader: PropTypes.bool,
  showVideo: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showEndConsultation: PropTypes.bool,
  isSessionEnd: PropTypes.bool,
  showToggle: PropTypes.bool,
  showChat: PropTypes.bool,
  showInfo: PropTypes.bool,
  isDoctor: PropTypes.bool,
  recipientName: PropTypes.string,
  recipientPhoto: PropTypes.string,
  preMeetingScreen: PropTypes.node
};

Header.defaultProps = {
  showSideBar: false,
  showHeader: false,
  showVideo: false,
  showBackButton: false,
  showEndConsultation: false,
  isSessionEnd: false,
  showToggle: false,
  showChat: false,
  showInfo: false
};

export default Header;
