import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Plus, PaperclipHorizontal, Info, Trash } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';
import Input from 'okadoc-component-ui/lib/Input';
import Select from 'okadoc-component-ui/lib/Select';
import Tray from 'components/atoms/tray';
import {
  UploadDocumentGlobalStyle,
  ButtonSend,
  ButtonUpload,
  ContentWrapper,
  Error,
  ExtraWording,
  FileName,
  Label
} from './styles';
import { MAX_LENGTH_DOCUMENT_NAME } from './constants';
import useAction, { MAX_UPLOAD_SIZE } from './actions';

const UploadDocument = (
  {
    t = () => {},
    country,
    lang,
    sideBarRef = null,
    isOpen = false,
    isShowVideoScreen = false,
    wlVersion = null,
    onHide = () => {}
  },
  ref
) => {
  const {
    error,
    form,
    dataTypeOption,
    widthTray,
    isSendLoading,
    saveDocument,
    onChangeForm,
    onClickClearFiles,
    onClickSend,
    onClickUpload,
    onValidate
  } = useAction({ country, lang, isOpen, sideBarRef, wlVersion, onHide });

  useImperativeHandle(
    ref,
    () => ({
      saveFacilityDocument: () => {
        saveDocument();
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  const isFormFilesAvailable = form.files.length > 0;

  return (
    <>
      <UploadDocumentGlobalStyle isShowVideoScreen={isShowVideoScreen} width={widthTray} />
      <Tray
        {...(isShowVideoScreen && { container: sideBarRef })}
        backdrop="static"
        backdropClassName="backdrop-drawer-document-upload"
        className="drawer-document-upload"
        show={isOpen}
        placement="end"
        onHide={onHide}
      >
        <Tray.Header closeButton>
          <Tray.Title>{t('Send File')}</Tray.Title>
        </Tray.Header>
        <Tray.Body>
          {isFormFilesAvailable && (
            <>
              {form.files.map((item, index) => (
                <ContentWrapper key={index} className="d-flex align-items-center">
                  <div className="d-flex align-items-center" style={{ flex: 1 }}>
                    <PaperclipHorizontal
                      size={24}
                      style={{ transform: 'rotate(90deg)' }}
                      color={Colors.grayscale.RhinoLight}
                    />
                  </div>
                  <FileName>{item.file_name}</FileName>
                  <div className="d-flex align-items-center justify-content-end" style={{ flex: 1 }}>
                    <Trash
                      size={24}
                      color={Colors.feedback.FireRed}
                      style={{ cursor: 'pointer' }}
                      onClick={onClickClearFiles}
                    />
                  </div>
                </ContentWrapper>
              ))}
            </>
          )}
          {!isFormFilesAvailable && (
            <>
              <ContentWrapper>
                <ButtonUpload variant="outline-blue" size="small" onClick={onClickUpload} block>
                  <Plus
                    size={24}
                    color={Colors.primary.NavyBlue}
                    weight="light"
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                  />
                  {t('Upload File')}
                </ButtonUpload>
              </ContentWrapper>
              <ContentWrapper>
                <ExtraWording>
                  <span>{t('Size Limit : {{size}} MB per file', { size: MAX_UPLOAD_SIZE })}</span>
                  <span>{t('Types allowed : .jpg, .jpeg, .png, .pdf')}</span>
                </ExtraWording>
                {error.files && (
                  <div className="d-flex align-items-center mt-10">
                    <Info size={24} color={Colors.feedback.FireRed} weight="light" />
                    <Error as="span" style={{ fontSize: 16, marginTop: 0, padding: '0 8px' }}>
                      {t(error.files, { size: MAX_UPLOAD_SIZE })}
                    </Error>
                  </div>
                )}
              </ContentWrapper>
            </>
          )}
          <ContentWrapper>
            <div className="d-flex flex-column">
              <Label>
                {t('Document Name')}
                <sup>*</sup>
              </Label>
              <Input
                value={form.document_title}
                onChange={e => {
                  onChangeForm('document_title', e.target.value);
                }}
                onKeyUp={e => onValidate('document_title', e.target.value)}
                hasError={error.document_title}
                maxLength={MAX_LENGTH_DOCUMENT_NAME}
              />
              {error.document_title && <Error>{t(error.document_title)}</Error>}
            </div>
          </ContentWrapper>
          <ContentWrapper>
            <div className="d-flex flex-column">
              <Label>
                {t('Document Type')}
                <sup>*</sup>
              </Label>
              <Select
                placeholder="Please Select"
                options={dataTypeOption}
                hasError={error.tag_id}
                value={form.tag_id ?? ''}
                onChange={value => onChangeForm('tag_id', +value)}
                scrollToSelectedValue
              />
              {error.tag_id && <Error>{t(error.tag_id)}</Error>}
            </div>
          </ContentWrapper>
          <ButtonSend
            variant="primary-blue"
            disabled={
              !form.document_title ||
              !form.tag_id ||
              form.files.length === 0 ||
              error.document_title ||
              error.tag_id ||
              error.files ||
              isSendLoading
            }
            onClick={onClickSend}
            block
          >
            {t('Send')}
          </ButtonSend>
        </Tray.Body>
      </Tray>
    </>
  );
};

UploadDocument.propTypes = {
  t: PropTypes.func.isRequired,
  sideBarRef: PropTypes.any,
  country: PropTypes.string,
  lang: PropTypes.string,
  isOpen: PropTypes.bool,
  isShowVideoScreen: PropTypes.bool,
  wlVersion: PropTypes.any,
  onHide: PropTypes.func.isRequired
};

export default forwardRef(UploadDocument);
