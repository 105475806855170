import styled from 'styled-components';

export const CSATSectionTop = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  text-align: center;

  & > :first-child {
    margin-bottom: 12px;
  }

  @media (min-width: 768px) {
    justify-content: flex-end;
    max-width: 600px;
    margin: auto;

    & > :first-child {
      margin-bottom: 16px;
    }
  }
`;

export const CSATSectionBottom = styled.div`
  padding-top: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 0;

  @media (min-width: 768px) {
    flex-grow: 1;

    .row {
      justify-content: center;
    }
  }
`;

export const CSATSectionHeader = styled.div`
  flex-grow: 0;
  align-items: center;
  margin-bottom: 25px;

  .modal-close__button {
    margin-left: auto;
    margin-right: 0;
  }
`;

export const Title = styled.h1`
  font-size: 24px;

  @media (min-width: 768px) {
    font-size: 30px;
  }
`;

export const SubTitle = styled.h1`
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const Text = styled.p`
  line-height: 1.5;
  font-size: ${props => (props.big ? '16px' : '14px')};

  @media (min-width: 768px) {
    font-size: ${props => (props.big ? '20px' : '16px')};
  }
`;

export const Divider = styled.div`
  width: 100%;
  background-color: #ecedee;
  height: 1px;
`;
