import styled, { css } from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const ChatInputGroup = styled.div`
  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid ${Colors.feedback.FireRed};
    `}
`;

export const ErrorMessage = styled.p`
  margin-top: 4px;
  color: ${Colors.feedback.FireRed};
`;
