import styled, { css } from 'styled-components';
import Button from 'okadoc-component-ui/lib/Button';
import Colors from 'okadoc-component-ui/lib/Colors';

export const DrawerContentQueue = styled.div`
  padding: 8px 0;
`;

export const ContentQueueIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const ContentQueueTitle = styled.h4`
  color: ${Colors.text.RhinoGrey};
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
`;

export const ContentQueueDescription = styled.p`
  color: ${Colors.text.RhinoGrey};
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 24px;
`;

export const ContentQueueButton = styled(Button)`
  ${props =>
    !props.outline &&
    css`
      margin-bottom: 16px;
    `}
`;
