/* eslint-disable new-cap */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import _isEmpty from 'lodash.isempty';
import { setStorage, getStorage } from 'libs/storage';
import to from 'libs/await-to';
import APILocale from 'libs/api/locale';

i18next.use(initReactI18next).init({
  lng: 'en',
  resources: {},
  defaultNS: 'common',
  react: {
    wait: true
  }
});

const load = async (country, lang, token) => {
  const languages = getStorage('locale') || {};
  i18next.changeLanguage(lang);

  if (!_isEmpty(languages?.[lang])) {
    i18next.addResourceBundle(lang, 'common', languages[lang]);
  } else {
    const [err, resp] = await to(APILocale.GetLanguage(country, lang, token));

    if (!err) {
      languages[lang] = resp?.data;
      i18next.addResourceBundle(lang, 'common', resp.data);
      setStorage('locale', languages);
    }
  }
};

export { load };
export default i18next;
