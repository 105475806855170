const RemainingTime = ({ remainingTime, isTestingMode }) => {
  if (!remainingTime?.hour && !isTestingMode) return null;

  return (
    <>
      <span className="text-bold">{remainingTime?.days || 0}&nbsp;</span>
      <span>{remainingTime?.days > 1 ? `Days` : `Day`},&nbsp;</span>
      <span className="text-bold">{remainingTime?.hour || 1}&nbsp;</span>
      <span>{remainingTime?.hour > 1 ? `Hours` : `Hour`},&nbsp;</span>
      <span className="text-bold">{remainingTime?.minute || 0}&nbsp;</span>
      <span>{remainingTime?.minute > 1 ? `Minutes` : `Minute`}</span>
    </>
  );
};

export default RemainingTime;
