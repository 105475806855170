import useActions from './actions';
import PropTypes from 'prop-types';
import CloseDrawer from 'components/molecules/close-drawer';
import { DRAWER_TYPE } from 'containers/video/chat-view/constants';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import IdlingDesktopModal from './desktop';
import { useMemo } from 'react';

const IdlingDrawer = ({ handleMoveToPreMeeting }) => {
  const isMobile = mobileBreakPoint();
  const { isIdle, description, btnPrimaryText, onClickSecondaryButton, onClickPrimaryButton, iconContent } = useActions(
    {
      handleMoveToPreMeeting
    }
  );
  const customAction = useMemo(
    () => ({
      primary: onClickPrimaryButton,
      secondary: onClickSecondaryButton
    }),
    [onClickPrimaryButton, onClickSecondaryButton]
  );

  if (isIdle)
    return isMobile ? (
      <CloseDrawer
        show={isIdle}
        type={DRAWER_TYPE.IDLING_MODAL}
        onHide={() => {}}
        onClickAccept={onClickPrimaryButton}
        onClickLeave={onClickSecondaryButton}
        description={description}
        iconContent={iconContent}
        btnPrimaryText={btnPrimaryText}
        customAction={customAction}
      />
    ) : (
      <IdlingDesktopModal
        show={isIdle}
        description={description}
        btnPrimaryText={btnPrimaryText}
        onClickPrimaryButton={onClickPrimaryButton}
        onClickSecondaryButton={onClickSecondaryButton}
        iconContent={iconContent}
      />
    );

  return null;
};

IdlingDrawer.propTypes = {
  handleMoveToPreMeeting: PropTypes.func.isRequired
};

export default IdlingDrawer;
