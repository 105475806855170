import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMeeting } from '@videosdk.live/react-sdk';
import useIdlingTimer from 'libs/hooks/useIdlingTimer';
import isAvailable, { FEATURE_IDLING_TIMER } from 'libs/feature-flags';
import useChat from 'libs/hooks/useChat';
import { useGlobalConfigStore } from 'zustandStore';
import { CONSULTATION_STATUS, EVENT_TYPE_IFRAME, QUIT_ENDING_SESSION_STEP } from 'libs/constant';

const formatTime = seconds => {
  if (seconds <= 0) return '00:00';

  const hours = parseInt(seconds / 60 / 60);
  const min = parseInt((seconds / 60) % 60);
  const sec = parseInt(seconds % 60);

  const times = [];
  if (hours > 0) times.push(hours);
  if (min >= 0 && seconds > 0) times.push(min);
  times.push(sec);

  return times.join(':').replace(/\b(\d)\b/g, '0$1');
};

const useActions = ({ handleMoveToPreMeeting }) => {
  const [isEndingSessionStep, setIsEndingSessionStep] = useState(false);
  const isMeetingStarted = useGlobalConfigStore(state => state.meetingStarted);
  const setMeetingStartedStore = useGlobalConfigStore(state => state.setMeetingStarted);
  const { practitioner: practitionerStatus, patient: patientStatus } = useGlobalConfigStore(
    state => state.participantStatus
  );
  const isConsultationWasOccured = useMemo(
    () =>
      practitionerStatus === CONSULTATION_STATUS.IN_CONSULTATION &&
      patientStatus === CONSULTATION_STATUS.LEFT_CONSULTATION,
    [practitionerStatus, patientStatus]
  );
  const {
    value: { isDoctor },
    action: { endSession, cancelEndSesionOrPayment }
  } = useChat();
  const { setTimer, stopIdleTimer, isIdle, isIdleNoResponse, idleNoResponseCountDown, IDLING_TIME_NO_RESPONSE } =
    useIdlingTimer();

  const btnPrimaryText = useMemo(() => {
    if (isDoctor && isConsultationWasOccured) {
      return 'End Consultation';
    }
    return 'Go to pre-meeting screen';
  }, [isConsultationWasOccured, isDoctor]);

  const description = useMemo(() => {
    if (isIdle) {
      return `You're the only one here, so this call will end in less than ${Math.ceil(
        IDLING_TIME_NO_RESPONSE / 60
      )} minutes. Do you want to stay in this call?`;
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, isIdleNoResponse, isDoctor, practitionerStatus, patientStatus]);

  const { participants, leave } = useMeeting();
  const participantSize = participants?.size ?? 0;
  const numOfParticipants = useMemo(() => participantSize ?? 0, [participantSize]);
  const iconContent = useMemo(() => formatTime(idleNoResponseCountDown), [idleNoResponseCountDown]);

  useEffect(() => {
    if (isAvailable([FEATURE_IDLING_TIMER])) {
      // run timer only when there is only one participant on the call
      if (isMeetingStarted && numOfParticipants === 1) {
        setTimer(prev => ({ ...prev, idlingTimer: true }));
      } else stopIdleTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMeetingStarted, numOfParticipants]);

  useEffect(() => {
    if (isIdleNoResponse && typeof handleMoveToPreMeeting === 'function') {
      leave();
      stopIdleTimer();
      setMeetingStartedStore(false);
      handleMoveToPreMeeting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdleNoResponse]);

  useEffect(() => {
    if (isIdle) {
      cancelEndSesionOrPayment(QUIT_ENDING_SESSION_STEP);
      isEndingSessionStep && setIsEndingSessionStep(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, isEndingSessionStep]);
  useEffect(() => {
    window.addEventListener('message', handlePractitionerEvent);
    return () => window.removeEventListener('message', handlePractitionerEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePractitionerEvent = event => {
    let message = {};
    try {
      message = JSON.parse(event?.data);
    } catch (error) {
      message = { type: '' };
    }

    // handle cancel end session, cancel payment or payment failed
    if (
      [
        EVENT_TYPE_IFRAME.DOCTOR_CANCEL_END_SESSION,
        EVENT_TYPE_IFRAME.PAYMENT_PROCESS_FAILED,
        EVENT_TYPE_IFRAME.DOCTOR_CANCEL_PAYMENT
      ].includes(message?.type)
    ) {
      setTimer(prev => ({ ...prev, idlingTimer: true }));
    }
  };

  const onClickPrimaryButton = useCallback(() => {
    stopIdleTimer();
    if (isDoctor && isConsultationWasOccured && typeof endSession === 'function') {
      setIsEndingSessionStep(true);
      endSession();
      // to handle if the practitioner does not do any action (leave endsession|payment modal being idle)
      setTimer(prev => ({ ...prev, idlingTimer: true }));
    } else if (typeof handleMoveToPreMeeting === 'function') {
      // close videosdk session
      leave();
      setMeetingStartedStore(false);
      handleMoveToPreMeeting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoctor, isConsultationWasOccured, handleMoveToPreMeeting]);

  const onClickSecondaryButton = () => {
    stopIdleTimer();
    setTimer(prev => ({ ...prev, idlingTimer: true }));
  };

  return {
    isIdle,
    isIdleNoResponse,
    description,
    onClickSecondaryButton,
    onClickPrimaryButton,
    btnPrimaryText,
    iconContent
  };
};

export default useActions;
