import { useEffect, useState } from 'react';
import { CONSULTATION_STATUS } from 'libs/constant';
import { useODDSSEContext } from 'libs/contexts/odd';
import { ODD_SSE_EVENTS, ODD_SSE_CONFIG } from 'libs/constant';
import { DRAWER_TYPE } from '../constants';

const useAction = ({ practitionerOnCall = false, practitionerStatus = '', totalQueue = '' }) => {
  const { addSubscriber } = useODDSSEContext();
  const [isJoinCall, setIsJoinCall] = useState(practitionerOnCall);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(DRAWER_TYPE.SCHEDULE_APPOINTMENT);

  useEffect(() => {
    const readyJoinCall =
      totalQueue === 0 || practitionerOnCall || practitionerStatus === CONSULTATION_STATUS.IN_CONSULTATION;

    setIsJoinCall(readyJoinCall);
  }, [practitionerOnCall, practitionerStatus, totalQueue]);

  useEffect(() => {
    addSubscriber({
      type: ODD_SSE_EVENTS.WAITING_TIME,
      config: [ODD_SSE_CONFIG.PATIENT],
      handler: () => {
        setShowAlert(true);
        setAlertType(DRAWER_TYPE.WAITING_APPOINTMENT);
      }
    });
  }, [addSubscriber]);

  return {
    isJoinCall,
    alertType,
    setAlertType,
    showAlert,
    setShowAlert
  };
};

export default useAction;
