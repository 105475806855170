import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from '../assets/images/loader.json';
import { isMobile } from 'libs/okaBrowserCheck';

const Loading = () => {
  const theWidth = isMobile ? 180 : 236;
  return (
    <div className="spin">
      <Lottie
        loop
        animationData={animationData}
        play
        style={{
          width: theWidth,
          height: theWidth
        }}
      />
    </div>
  );
};

export default Loading;
