import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const onErrorHandling = eh => {
  eh.target.onerror = null;
  eh.target.src = require(`../assets/images/img-placeholder.png`);
};

const Svg = ({ name, className, external = false, alt, ...rest }) => {
  let source = '';
  if (external) source = name;
  else source = require(`../assets/images/${name}.svg`);

  return (
    <img
      src={source}
      alt={alt}
      className={classNames({ [className]: className })}
      onError={onErrorHandling}
      {...rest}
    />
  );
};

Svg.propTypes = {
  name: PropTypes.string,
  classNames: PropTypes.string,
  external: PropTypes.bool,
  alt: PropTypes.string
};

Svg.defaultProps = {
  alt: ''
};

export default Svg;
