export const downloadFile = (filepath, filename) => {
  fetch(filepath).then(response => {
    response.blob().then(blob => {
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = filename;
      anchor.click();
    });
  });
};
