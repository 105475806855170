import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Png from 'components/Png';
import StarRating from './star-rating';
import { CSATSectionBottom, CSATSectionTop, Title, SubTitle, Text, Divider, CSATSectionHeader } from './styles';
import { useCSATContext } from 'libs/contexts/csat';
import { CSAT_QUESTION_TYPE, CSAT_STEPS } from 'libs/constant';
import useActions from './actions';

const FeedbackSection = ({ toggle, consultationCompletedUrl }) => {
  const { t } = useTranslation();
  const { isRedirecting, goToAppointmentDetail, backToChatHistory, goToConsultationCompletedUrl } =
    useActions(consultationCompletedUrl);
  const {
    step,
    appointment,
    questions,
    questionIndex,
    isLastQuestion,
    onNextQuestion,
    onSubmitAnswers,
    answers,
    onQuestionChange
  } = useCSATContext();

  const [isAbleToContinue, setIsAbleToContinue] = useState(false);
  const { range, multipleChoice } = useMemo(() => {
    const item = { range: {}, multipleChoice: {} };
    const currQst = questions?.[questionIndex] || [];
    if (currQst?.questions?.length > 0) {
      item.range = currQst.questions.find(qt => qt?.type === CSAT_QUESTION_TYPE.RANGE) || {};
      item.multipleChoice = currQst.questions.find(qt => qt?.type === CSAT_QUESTION_TYPE.MULTIPLE_CHOICES) || {};
    }
    return item;
  }, [questions, questionIndex]);

  const onRateChange = useCallback(
    (option, idx) => {
      onQuestionChange(range, option, idx);
    },
    [onQuestionChange, range]
  );

  useEffect(() => {
    const rangeQst = range?.fg_required ? !!answers?.[range?.id]?.answer : true;
    const mcQstQst = multipleChoice?.fg_required ? answers?.[multipleChoice?.id]?.answer?.length > 0 : true;

    setIsAbleToContinue(rangeQst && mcQstQst);
  }, [answers, range, multipleChoice]);

  return (
    <div className="modal-body d-flex flex-column py-30">
      {step === CSAT_STEPS.QUESTION_SCREEN && (
        <CSATSectionHeader className="row">
          <div className="col"></div>
          <div className="col-6 text-center">
            <Text>
              <span className="text-bold">{questionIndex + 1}</span> / <span>{questions?.length}</span>
            </Text>
          </div>
          <div className="col text-right">
            <button className="button modal-close__button" onClick={toggle}>
              <Png name="ic-close" className="modal-close__icon" />
            </button>
          </div>
        </CSATSectionHeader>
      )}
      <CSATSectionTop className="justify-content-start">
        {step === CSAT_STEPS.COMPLETE_SCREEN && (
          <>
            <div className="d-flex align-items-center">
              <Png
                external
                name="https://img.okadoc.com/photos/block_images/img/icon/Group-4@2x.png"
                alt="doctor profile"
                width="28px"
                className="mr-10"
              />
              <Title>{t('Consultation Completed!')}</Title>
            </div>
            <Text>
              {t('This consultation has been ended by {{practitionerName}}.', {
                practitionerName: appointment?.doctorName
              })}
              &nbsp;
              {appointment?.payment?.total > 0 &&
                t('{{totalPayment}} has been successfully charged to your chosen payment method.', {
                  totalPayment: appointment?.payment?.total_wording
                })}
            </Text>
            <Divider className="my-30" />
          </>
        )}
        <div className="d-flex flex-column justify-content-center">
          {questions?.length > 0 && <SubTitle>{t(questions?.[questionIndex]?.title)}</SubTitle>}
          {range?.options?.length > 0 && (
            <div className="mt-15">
              <StarRating onChange={onRateChange} stars={range.options} rate={answers?.[range?.id]?.answerIdx} />
            </div>
          )}
          {answers?.[range?.id]?.answer && (
            <div className="mt-15">
              <div className="mb-15">
                <Text>{t(answers?.[range?.id]?.message)}</Text>
              </div>
              <div className="row row--around-sm justify-content-center">
                {multipleChoice?.options?.map((item, index) => (
                  <div className="col-auto" key={`qty#${questionIndex}-${index}`}>
                    <div className="pill-checkbox" onClick={() => onQuestionChange(multipleChoice, item, index)}>
                      <div
                        className={`pill-checkbox__label ${
                          answers?.[multipleChoice?.id]?.answer?.includes(item?.answer) ? 'active' : ''
                        }`}
                      >
                        {t(item?.answer)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </CSATSectionTop>
      {[CSAT_STEPS.COMPLETE_SCREEN, CSAT_STEPS.THANK_YOU_SCREEN].includes(step) && (
        <CSATSectionBottom>
          <div className="row">
            {consultationCompletedUrl ? (
              <div className="col-12 col-md-auto">
                <button
                  disabled={isRedirecting}
                  onClick={goToConsultationCompletedUrl}
                  className="button button-big w-100 justify-content-center text-blue"
                >
                  {t('Go back')}
                </button>
              </div>
            ) : (
              <>
                <div className="col-12 col-md-auto">
                  <button
                    disabled={isRedirecting}
                    onClick={backToChatHistory}
                    className="button button-big w-100 justify-content-center text-blue"
                  >
                    {t('Back to chat history')}
                  </button>
                </div>
                <div className="col-12 col-md-auto">
                  <button
                    disabled={isRedirecting}
                    onClick={goToAppointmentDetail}
                    className="button button-big green outline w-100 justify-content-center"
                  >
                    {t('Appointment Details')}
                  </button>
                </div>
              </>
            )}
          </div>
        </CSATSectionBottom>
      )}

      {step === CSAT_STEPS.QUESTION_SCREEN && (
        <CSATSectionBottom>
          <div className="row">
            {!isLastQuestion ? (
              <div className="col-12 col-md-auto">
                <button
                  disabled={!isAbleToContinue}
                  className="button button-big green w-100 justify-content-center"
                  onClick={onNextQuestion}
                >
                  {t('Continue')}
                </button>
              </div>
            ) : (
              <div className="col-12 col-md-auto">
                <button
                  disabled={!isAbleToContinue}
                  className="button button-big green w-100 justify-content-center"
                  onClick={onSubmitAnswers}
                >
                  {t('Submit')}
                </button>
              </div>
            )}
          </div>
        </CSATSectionBottom>
      )}
    </div>
  );
};

export default FeedbackSection;
