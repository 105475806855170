import to from '../../await-to';
import OkaAxios from '../../axios';

export default class AppointmentAPI {
  static PostAppointmentNeedConfirmConsent = async ({ appointmentNumber, payload }) => {
    const endpoint = `/patientapp/appointments/${appointmentNumber}/consent`;

    return to(OkaAxios.appointment.post(endpoint, payload));
  };
  static PutCancelAppointment = async appointmentNumber => {
    const endpoint = `/patientapp/appointments/${appointmentNumber}/cancel`;

    return to(OkaAxios.appointment.put(endpoint));
  };
  static GetAppointmentDetail = async ({ appointmentNumber, isDoctor }) => {
    const endpoint = `/${isDoctor ? 'doctorapp/appointment' : 'patientapp/appointments'}/${appointmentNumber}`;

    return to(OkaAxios.appointment.get(endpoint));
  };
  static GetChatHistory = async ({ appointmentNumber, chatId = '' }) => {
    const endpoint = '/telemedicine/chat/history';
    let params = {
      appointment_number: appointmentNumber
    };

    if (chatId) {
      params = { ...params, ids: chatId };
    }
    return to(OkaAxios.appointment.get(endpoint, { params }));
  };
  static SaveChat = async ({ appointmentNumber, message }) => {
    const postData = {
      appointment_number: appointmentNumber,
      format: message.format,
      text: message.text,
      time: message.time
    };
    const endpoint = '/telemedicine/chat/save';

    return to(OkaAxios.appointment.post(endpoint, postData));
  };
  static SaveImage = async ({ appointmentNumber, image, filename }) => {
    const postData = {
      appointment_number: appointmentNumber,
      image,
      filename: filename
    };
    const endpoint = '/telemedicine/chat/image';

    return to(OkaAxios.appointment.post(endpoint, postData));
  };
  static CreateFacilityDocuments = async ({ appointmentNumber, payload }) => {
    return to(
      OkaAxios.appointment.post(`/doctorapp/appointment/${appointmentNumber}/documents`, payload, {
        // fg_insert_chat_history = 1 means BE will insert uploaded document into chat history and
        // include the document ID in the api response
        // https://okadoc.atlassian.net/browse/SA-1395
        params: { fg_insert_chat_history: 1 }
      })
    );
  };
  /**
   * Get CSAT Questions List
   * @param {*} appointmentNumber
   * @return {Object} Promise
   */
  static getCSATQuestions = appointmentNumber => {
    const endPoint = `/csat/questions`;
    const params = { appointment_number: appointmentNumber };
    return to(OkaAxios.appointment.get(endPoint, { params }));
  };
  static submitCSATAnswers = payloads => {
    const endPoint = `/csat/answers`;
    return to(OkaAxios.appointment.post(endPoint, payloads));
  };
}
