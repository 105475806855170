// * https://github.com/wojtekmaj/react-pdf#user-guide

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { pdfjs, Document, Page } from 'react-pdf';
import { useTranslation } from 'react-i18next';

import { ERROR_MESSAGE, ERROR_TYPE } from './constants';
import Pagination from './pagination';
import { PDFViewerContainer } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const defaultWidth = 300;

const PDFViewer = ({
  children = null,
  file = '',
  renderMode = 'canvas',
  width: widthProps = null,
  onLoadSuccess = () => {}
}) => {
  const [errorMessage, setErrorMessage] = useState(ERROR_MESSAGE.default);
  const [width, setWidth] = useState(widthProps);

  const pdfViewerRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    setWidth(widthProps);
    if (widthProps === null) {
      setWidth(pdfViewerRef?.current?.clientWidth ?? defaultWidth);
    }
  }, [pdfViewerRef, widthProps]);

  const onLoadError = type => {
    const errorName = type?.name?.toLowerCase() ?? ERROR_TYPE.DEFAULT;
    let text = ERROR_MESSAGE.default;

    if (errorName === ERROR_TYPE.INVALIDPDFEXCEPTION) {
      text = ERROR_MESSAGE.invalidpdfexception;
    }
    setErrorMessage(text);
  };

  const props = {
    width
  };

  return (
    <PDFViewerContainer ref={pdfViewerRef}>
      <Document
        file={file}
        error={t(errorMessage)}
        renderMode={renderMode}
        onLoadSuccess={onLoadSuccess}
        onLoadError={onLoadError}
      >
        {typeof children === 'function' ? children(props) : children}
      </Document>
    </PDFViewerContainer>
  );
};

PDFViewer.Page = Page;
PDFViewer.Pagination = Pagination;

PDFViewer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  file: PropTypes.string.isRequired,
  renderMode: PropTypes.oneOf(['canvas', 'svg']),
  width: PropTypes.number,
  onLoadSuccess: PropTypes.func
};

export default PDFViewer;
