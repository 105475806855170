import Png from 'components/Png';
import { useTranslation } from 'react-i18next';
import useActions from './actions';
import { CSATSectionBottom, CSATSectionTop, Title, Text } from './styles';

const ThankYouSection = ({ consultationCompletedUrl }) => {
  const { t } = useTranslation();
  const { isRedirecting, goToAppointmentDetail, backToChatHistory, goToConsultationCompletedUrl } =
    useActions(consultationCompletedUrl);

  return (
    <div className="modal-body d-flex flex-column py-30">
      <CSATSectionTop className="justify-content-center">
        <div className="pb-10">
          <Png
            external
            name="https://img.okadoc.com/photos/block_images/img/icon/Group-4@2x.png"
            alt="doctor profile"
            width="44px"
          />
        </div>
        <Title>{t('Thank you!')}</Title>
        <Text>{t('Your feedback will help us to improve and provide a better experience.')}</Text>
      </CSATSectionTop>
      <CSATSectionBottom>
        <div className="row">
          {consultationCompletedUrl ? (
            <div className="col-12 col-md-auto">
              <button
                disabled={isRedirecting}
                onClick={goToConsultationCompletedUrl}
                className="button button-big w-100 justify-content-center text-blue"
              >
                {t('Go back')}
              </button>
            </div>
          ) : (
            <>
              <div className="col-12 col-md-auto">
                <button
                  disabled={isRedirecting}
                  onClick={backToChatHistory}
                  className="button button-big w-100 justify-content-center text-blue"
                >
                  {t('Back to chat history')}
                </button>
              </div>
              <div className="col-12 col-md-auto">
                <button
                  disabled={isRedirecting}
                  onClick={goToAppointmentDetail}
                  className="button button-big green outline w-100 justify-content-center"
                >
                  {t('Appointment Details')}
                </button>
              </div>
            </>
          )}
        </div>
      </CSATSectionBottom>
    </div>
  );
};

export default ThankYouSection;
