import React from "react";
import { Toast as T } from "react-bootstrap";
import ToastContainer from 'react-bootstrap/ToastContainer';
import PropTypes from "prop-types";
import { CheckCircle, Warning, X } from "phosphor-react";
import Colors from "okadoc-component-ui/lib/Colors";
import {
  Wrap,
  ToastWrapper,
  ToastContent,
  ToastClose,
} from "./styles";

const Component = ({ message, type = "success", onClose }) => {
  return (
    <Wrap>
      <ToastContainer>
        <ToastWrapper autohide show onClose={onClose}>
          <T.Header closeButton={false}>
            <ToastContent>
              {type === "success" && (
                <CheckCircle
                  weight="fill"
                  size={20}
                  color={Colors.primary.NiagaraGreen}
                />
              )}
              {type === "danger" && (
                <Warning
                  weight="fill"
                  size={20}
                  color={Colors.feedback.FireRed}
                />
              )}
            </ToastContent>
            <ToastContent>{message}</ToastContent>
            <ToastContent>
              <ToastClose role="button" onClick={onClose}>
                <X
                  color={type === "danger" ? Colors.text.RhinoGrey : null}
                />
              </ToastClose>
            </ToastContent>
          </T.Header>
        </ToastWrapper>
      </ToastContainer>
    </Wrap>
  );
};

Component.propTypes = {
  type: PropTypes.oneOf(["success", "danger"]),
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Component;
