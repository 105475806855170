/* eslint-disable new-cap */
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppointmentAPI from '../api/appointment';
import AccountAPI from '../api/account';
import ConferenceAPI from '../api/conference';
import { sendMessage } from '../message';
import { RESPONSE_CODE, RESPONSE_STATUS } from '../constant';
import { getQueryParams } from '../url';
import isAvailable, { FEATURE_ODD_APPOINTMENT } from 'libs/feature-flags';

export const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [errors, setErrors] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [telemedicineData, setTelemedicineData] = useState();
  const [chatHistory, setChatHistory] = useState([]);
  const [telemedicineQueue, setTelemedicineQueue] = useState({});
  const location = useLocation();
  const params = getQueryParams(location);
  const lang = params?.lang;
  const isDoctor = params?.type === 'doctor';
  const [isLoadingChatHistory, setIsLoadingChatHistory] = useState(false);

  const [doctor, setDoctor] = useState({});
  const [patient, setPatient] = useState({});
  const [isLoadingRequestRoom, setLoadingReqRoom] = useState(true);
  const [isLoadingAppointment, setLoadingAppointment] = useState(true);
  const [isShowAppDrawer, setIsShowAppDrawer] = useState(false);
  const [isVideoStarted, setIsVideoStarted] = useState(false);

  /**
   * fetch active participant
   * @param {string|number} appointmentNumber
   */
  const fetchActiveParticipant = async appointmentNumber => {
    // eslint-disable-next-line new-cap
    const [err, response] = await ConferenceAPI.GetActiveParticipant({ appointmentNumber });

    // find participant match with current user
    const hasJoined = response?.data?.data?.find(item => item?.user_id?.includes(isDoctor ? 'doctor' : 'patient'));

    return { err, hasJoined: typeof hasJoined !== 'undefined' };
  };

  /**
   * It gets the appointment detail from the API.
   * @param {string} appointmentNumber - appointment number.
   */
  const getAppointmentDetail = async ({ appointmentNumber }) => {
    setLoadingAppointment(true);

    const [err, response] = await AppointmentAPI.GetAppointmentDetail({
      isDoctor,
      appointmentNumber
    });

    if (err) {
      const statusCode = err.response?.status;
      setErrors(err.response);
      setLoadingAppointment(false);
      if (![RESPONSE_CODE.EXPIRED, RESPONSE_CODE.OUTSIDE_TIME_RANGE, RESPONSE_CODE.INVALID].includes(statusCode)) {
        throw new Error('Error on getAppointmentDetail');
      }
    }

    const [, accountResponse] = await AccountAPI.GetAccountDetail(isDoctor);
    const data = !isDoctor ? response?.data : response?.data?.data; // do different ways to retrieve data due to different structure response
    const accountData = !isDoctor ? accountResponse?.data : accountResponse?.data?.data; // do different ways to retrieve data due to different structure response
    const patientName = `${data?.patient?.first_name} ${data?.patient?.last_name}`;
    const doctorName = data?.doctor?.fullName;
    const telemedicineQueueData = data?.telemedicine_queue;

    if (data && accountData) {
      const appointmentDetail = {
        userId: isDoctor
          ? accountData.info.user_id
          : accountData?.patients?.user_id ?? accountData?.patients?.PatientID, // fallback if there's non user_id, then using PatientID
        appointmentNumber,
        participantName: isDoctor ? doctorName : patientName,
        doctorName,
        patient: patientName,
        practitioner: data?.doctor?.title,
        startTime: data?.start,
        endTime: !isDoctor ? data?.endTimeWithoutExpiration || data?.endTime : data?.endTime || data?.end,
        serviceCategoryName: data?.serviceCategoryName || data?.calendar_service_category_name,
        status: isDoctor ? data?.appointment_status : data?.status,
        facilityId: data?.facility_id,
        fgConsent: data?.fg_consent === 1 ?? false,
        telemedicineConsentDescription: data?.telemedicine_consent_description,
        telemedicineConsentFileUrl: data?.telemedicine_consent_file_url,
        telemedicineConsentFileName: data?.telemedicine_consent_filename,
        isODDConsultation:
          isAvailable([FEATURE_ODD_APPOINTMENT]) && Boolean(data?.fg_odd_appointment ?? data?.fg_on_demand), // first condition for patientapp, second condition for doctorapp
        fgCSAT: data?.fg_csat || false,
        payment: data?.payment || {}
      };

      setAppointment(appointmentDetail);
      setDoctor(data?.doctor);
      setPatient(data?.patient);
      setTelemedicineQueue(telemedicineQueueData);
    }

    setLoadingAppointment(false);
  };

  /**
   * It gets the chat history of an appointment
   * @param {string} appointmentNumber - appointment number.
   */
  const getChatHistory = async ({ appointmentNumber }) => {
    setIsLoadingChatHistory(true);
    const [, response] = await AppointmentAPI.GetChatHistory({ appointmentNumber });

    const data = response?.data;
    if (data) {
      setChatHistory(data.data);
      setIsLoadingChatHistory(false);
    }
  };

  const endSession = useCallback(async () => {
    const queryParams = getQueryParams(window.location);
    const domainIframe = queryParams?.domain;

    sendMessage(
      {
        message: {
          type: 'endSession',
          session_id: appointment?.appointmentNumber
        }
      },
      domainIframe
    );
  }, [appointment]);

  const cancelEndSesionOrPayment = useCallback(type => {
    const queryParams = getQueryParams(window.location);
    const domainIframe = queryParams?.domain;

    sendMessage(
      {
        message: { type }
      },
      domainIframe
    );
  }, []);

  const saveChat = async ({ appointmentNumber, message }) => {
    const [err, response] = await AppointmentAPI.SaveChat({ appointmentNumber, message });

    if (err) {
      const statusCode = err.response?.status;
      const errorMessage = err.response;
      setErrors({ message: errorMessage, status: statusCode });
    }
    return response;
  };

  const saveImage = async ({ appointmentNumber, image, filename }) => {
    const [err, response] = await AppointmentAPI.SaveImage({ appointmentNumber, image, filename });

    if (err) {
      const statusCode = err.response?.status;
      const errorMessage = err.response;
      setErrors({ message: errorMessage, status: statusCode });
    }
    return response;
  };

  const requestRoom = useCallback(async ({ appointmentNumber }) => {
    setLoadingReqRoom(true);

    const [err, response] = await ConferenceAPI.RequestRooom({ appointmentNumber });
    const data = response?.data?.data;

    if (err) {
      const statusCode = err.response?.status;
      const errorMessage = err.response?.data?.message;

      let status;
      if (statusCode === RESPONSE_CODE.EXPIRED) status = RESPONSE_STATUS.EXPIRED;
      if (statusCode === RESPONSE_CODE.OUTSIDE_TIME_RANGE) status = RESPONSE_STATUS.OUTSIDE_TIME_RANGE;
      if (statusCode === RESPONSE_CODE.INVALID) status = RESPONSE_STATUS.INVALID;

      setErrors({ message: errorMessage, status });
      setLoadingReqRoom(false);
      if (![RESPONSE_CODE.EXPIRED, RESPONSE_CODE.OUTSIDE_TIME_RANGE, RESPONSE_CODE.INVALID].includes(statusCode)) {
        throw new Error('Error on requestRoom');
      }
    }

    if (!err && data) {
      const theTelemedicineData = {
        telemedicineStatus: data?.telemedicine_status,
        meetingId: data?.external_meeting_id,
        token: data?.token,
        startTime: data?.appointment_start_time,
        endTime: data?.appointment_end_time,
        shallrecorded: !!data?.fg_shall_recorded
      };

      setTelemedicineData(theTelemedicineData);
      setLoadingReqRoom(false);
    }

    setLoadingReqRoom(false);
  }, []);

  const handleShowAppDrawer = useCallback(
    async ({ checkActive = false, callback = () => {} }) => {
      if (checkActive && isVideoStarted) {
        callback?.();
      } else {
        setIsShowAppDrawer(prevState => !prevState);
      }
    },

    [isVideoStarted]
  );

  const isLoadAppointmentAndRoom = isLoadingRequestRoom || isLoadingAppointment;

  const collectionProps = useMemo(
    () => ({
      value: {
        appointment,
        telemedicine: telemedicineData,
        doctor,
        patient,
        errors,
        isLoadAppointmentAndRoom,
        chatHistory,
        isLoadingChatHistory,
        isDoctor,
        isShowAppDrawer,
        lang,
        telemedicineQueue
      },
      action: {
        fetchActiveParticipant,
        getAppointmentDetail,
        requestRoom,
        getChatHistory,
        saveChat,
        saveImage,
        setIsShowAppDrawer,
        setIsVideoStarted,
        endSession,
        handleShowAppDrawer,
        cancelEndSesionOrPayment
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      appointment,
      telemedicineData,
      doctor,
      patient,
      errors,
      isLoadAppointmentAndRoom,
      isShowAppDrawer,
      chatHistory,
      isLoadingChatHistory,
      isVideoStarted,
      telemedicineQueue
    ]
  );

  return <ChatContext.Provider value={collectionProps}>{children}</ChatContext.Provider>;
};

export default ChatProvider;
