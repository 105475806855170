import React from 'react';
import PropTypes from 'prop-types';
import Png from './Png';
import { X } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';

const types = {
  end: 'end',
  chat: 'chat',
  video: 'video',
  info: 'info',
  back: 'back',
  close: 'close',
  switch: 'switch'
};

const icon = {
  chat: 'https://img.okadoc.com/photos/block_images/img/icon/ic-chatbox.svg',
  video: 'https://img.okadoc.com/photos/block_images/img/icon/videocam-blue.svg',
  info: 'https://img.okadoc.com/photos/block_images/img/icon/ic-information.svg',
  switch: 'https://img.okadoc.com/photos/block_images/img/icon/camera-switch.svg'
};

const Button = ({ disabled, onClick, active, unread, variant }) => {
  if (variant === types.end) {
    return (
      <button
        disabled={disabled}
        className="button red"
        onClick={onClick}
        style={{
          alignSelf: 'center',
          margin: '5px 10px'
        }}
      >
        <span className="d-md-none">End</span>
        <span className="d-none d-md-block">End Consultation</span>
      </button>
    );
  }

  if (variant === types.back) {
    return (
      <button className="button clear align-items-center" onClick={onClick}>
        <Png name="arrow-left" alt="back arrow" width="10px" />
      </button>
    );
  }

  if (variant === types.close) {
    return (
      <button className="button clear align-items-center" onClick={onClick}>
        <X color={Colors.grayscale.RhinoGrey} size={16} weight="light" />
      </button>
    );
  }

  if (variant === types.switch) {
    return (
      <button disabled={disabled} className="button" onClick={onClick}>
        <Png name={icon[variant]} alt="switch camera" width="20px" className="img-responsive" external />
      </button>
    );
  }

  return (
    <button
      disabled={disabled}
      className={`button clear-blue ${active ? 'active' : ''} ${unread ? 'unread-chat' : ''}`}
      onClick={onClick}
    >
      <Png name={icon[variant]} alt="chat message" width="15px" className="img-responsive" external />
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  unread: PropTypes.bool,
  variant: PropTypes.oneOf(types)
};

Button.defaultProps = {
  disabled: false,
  onClick: () => {},
  active: false,
  unread: false,
  variant: types.chat
};

export default Button;
