import styled, { createGlobalStyle, css } from 'styled-components';
import Button from 'okadoc-component-ui/lib/Button';
import Colors from 'okadoc-component-ui/lib/Colors';

export const UploadDocumentGlobalStyle = createGlobalStyle`
  .backdrop-drawer-document-upload.offcanvas-backdrop {
    ${props =>
      props.isShowVideoScreen &&
      css`
        position: absolute;
      `}
  }
  .drawer-document-upload.offcanvas {
    &-end {
      width: ${props => props.width}px;

      ${props =>
        props.isShowVideoScreen &&
        css`
          top: 0;
        `}
    }
    .offcanvas {
      &-header {
        border-bottom: 1px solid #e8e9ea;
        position: relative;

        .btn-close {
          position: absolute;
          right: 16px;
        }
      }
      &-title {
        flex: 1;
        text-align: center;

        &.h5 {
          color: ${Colors.text.RhinoGrey};
          font-size: 20px;
          font-weight: 700;
        }
      }
      &-body {
        padding: 24px 10px;
      }
    }

    .searchbar-select {
      &-control {
        border-radius: 4px !important;
        font-size: 16px !important;
      }
      &-menu__option-label {
        font-size: 16px !important;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ButtonUpload = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover {
    svg {
      filter: brightness(0) invert(1);
    }
  }
`;

export const ButtonSend = styled(Button)``;

export const Error = styled.p`
  color: ${Colors.feedback.FireRed};
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 4px;
`;

export const ExtraWording = styled.div`
  color: ${Colors.text.RhinoLight};
  font-size: 14px;
  line-height: 1.43;

  span {
    display: block;
  }
`;

export const FileName = styled.div`
  color: ${Colors.primary.NavyBlue};
  font-size: 14px;
  font-weight: 400;
  flex: 10;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const Label = styled.label`
  color: ${Colors.text.RhinoGrey};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 4px;

  sup {
    color: ${Colors.feedback.FireRed};
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 4px;
    top: unset;
    position: relative;
    vertical-align: baseline;
  }
`;
