import Lottie from 'react-lottie-player';
import animationData from 'assets/images/loader.json';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';

const LoadingDots = ({ className, isLoading = true }) => {
  const isMobile = mobileBreakPoint();
  const theWidth = isMobile ? 180 : 236;

  return (
    <div className={`loading-dots ${className}`}>
      <Lottie
        loop
        animationData={animationData}
        play={isLoading}
        segments={[0, 210]}
        style={{
          width: theWidth,
          height: theWidth
        }}
      />
    </div>
  );
};

export default LoadingDots;
