/* eslint-disable new-cap */
import React from 'react';
import useChat from 'libs/hooks/useChat';
import InfoView from './info-view';
import { useGlobalConfigStore } from 'zustandStore';

const InfoViewEvent = ({ isXToken }) => {
  const { value } = useChat();
  const isDoctorView = value?.isDoctor;

  const participantStatus = useGlobalConfigStore(state => state.participantStatus);

  return (
    <InfoView
      isXToken={isXToken}
      status={isDoctorView ? participantStatus?.patient : participantStatus?.practitioner}
    />
  );
};

export default InfoViewEvent;
