import React from 'react';
import Button from 'okadoc-component-ui/lib/Button';
import { useTranslation } from 'react-i18next';
import { getQueryParams } from 'libs/url';
import { Container, Title, Description, Link, Img, ButtonContainer } from './styles';

const ErrorPage = () => {
  const { t } = useTranslation();
  const queryParams = getQueryParams(window.location);
  const domain = queryParams?.domain;
  const country = queryParams?.country;
  const lang = queryParams?.lang;
  const manageApptUrl = `https://${domain}/${country}-${lang}/dashboard/appointments`;
  const handleRefresh = () => {
    window.location.reload();
  };

  const handleGoToAppointments = () => {
    window.parent.location.href = manageApptUrl;
  };

  return (
    <Container>
      <div>
        <Img
          src="https://img.okadoc.com/photos/block_images/img/icon/404-not-found.svg"
          alt="An unexpected error occur while trying to access"
        />
        <Title>{t('An unexpected error occur while trying to access')}</Title>
        <Description>
          {t(
            'Some technical difficulties have appeared. Please, go to your appointment details and try again to access the video consultation.'
          )}
        </Description>
        <ButtonContainer>
          <Button block variant="primary-green" onClick={handleRefresh}>
            Refresh Page
          </Button>
        </ButtonContainer>
        <Link onClick={handleGoToAppointments}>{t('Go to My Appointments')}</Link>
      </div>
    </Container>
  );
};

export default ErrorPage;
