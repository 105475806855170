/* eslint-disable new-cap */
import { OkaHMAC, refreshXDate, getTimeDiffFromCookie } from 'okadoc-libs';
import _forEach from 'lodash.foreach';
import moment from 'moment';
import axios from 'axios';

import { getToken } from '../cookie';
import { RESPONSE_CODE, RESPONSE_STATUS } from '../constant';
import LocaleAPI from 'libs/api/locale';
import { getQueryParams } from '../url';

const kongHMAC = new OkaHMAC(process.env.REACT_APP_KONG_SERVICES_CONFIG);

const appointmentService = process.env.REACT_APP_URL_SERVICE_APPOINTMENT;
const localeService = process.env.REACT_APP_URL_SERVICE_LOCALE;
const conferenceService = process.env.REACT_APP_URL_SERVICE_CONFERENCE;

const params = getQueryParams(window.location);
const defaultAcceptLanguage = params?.lang ? params?.lang : 'en';
const defaultConfig = {
  headers: {
    'client-id': process.env.REACT_APP_CLIENT_ID,
    'app-version': process.env.REACT_APP_CLIENT_VERSION,
    'Content-Type': 'application/json'
  }
};

const createAxios = baseURL =>
  axios.create({
    ...defaultConfig,
    baseURL
  });

const updateResponseWithTimeDuration = response => {
  response.config.metadata.endTime = +moment().unix();
  response.startTime = response.config.metadata.startTime;
  response.endTime = response.config.metadata.endTime;
  response.duration = response.config.metadata.endTime - response.config.metadata.startTime;
};

const OkaAxios = (function () {
  const theObj = {
    locale: createAxios(localeService),
    appointment: createAxios(appointmentService),
    conference: createAxios(conferenceService),
    account: createAxios(conferenceService)
  };

  _forEach(theObj, (value, serviceName) => {
    // eslint-disable-next-line no-undefined
    if (value.interceptors !== undefined) {
      value.interceptors.request.use(conf => {
        // Only set default value
        conf.name = serviceName;
        conf.metadata = { startTime: +moment().unix() };

        // Gets the `time-difference` value from cookie only
        // skipping from `calling-api`
        const timeDiff = getTimeDiffFromCookie({
          skipGetServerTime: true,
          // since there is no server rendering (like NextJs) so `isServer` default set to false
          isServer: false
        });
        conf.timeDiff = timeDiff;

        // updating `kong-hmac` headers
        kongHMAC.updateHeaders(conf);

        return conf;
      });

      value.interceptors.response.use(
        response => {
          updateResponseWithTimeDuration(response);

          return response;
        },
        errResponse => {
          if (axios.isCancel(errResponse)) {
            errResponse.isAxiosCancel = true;
            errResponse.message = 'Request cancelled';
            return Promise.reject(errResponse);
          }

          const status = errResponse?.response?.status;
          const message = errResponse?.response?.data?.message;

          if (status === RESPONSE_CODE.UNAUTHIRIZED && ['authorization is revoked'].includes(message)) {
            window.location.href = 'https://staging.okadoc.co/en-ae/account'; // temporarily use this url for redirection when user is not logged in
          }

          // refresh HMAC headers when the `X-Date` field is invalid
          // due to incorrect user's local Datetime.
          if (status === RESPONSE_CODE.KONG_ERROR && message === RESPONSE_STATUS.KONG_INVALID_XDATE) {
            const { config: prevConfig } = errResponse;

            return refreshXDate(prevConfig, updatedConfig => {
              // re-updating `kong-hmac` headers
              // when `refreshing-xdate` done.
              kongHMAC.updateHeaders(updatedConfig);
            });
          }

          return Promise.reject(errResponse);
        }
      );
    }
  });

  return theObj;
})();

export const ReinitAPIRequest = options => {
  if (options?.service_url) {
    _forEach(OkaAxios, (value, serviceName) => {
      // change base url based on service_url
      if (options.service_url?.[serviceName]) {
        value.defaults.baseURL = `${options.service_url[serviceName].url.replace(/\/?$/, '')}`;
      }

      value.interceptors.request.use(conf => {
        // Custom Headers
        if (options?.headers) {
          conf.headers = {
            ...conf?.headers,
            ...options?.headers
          };
        }
        const isContainServiceUrl = /https|http/.test(conf.url);
        if (options.service_url?.[serviceName] && !isContainServiceUrl) {
          conf.url = `${options.service_url[serviceName].url.replace(/\/?$/, '')}${conf.url}`;
        }

        let token = getToken(options?.isDoctorSide);
        if (options?.tokenFromPostMessage) token = options?.tokenFromPostMessage;
        if (options?.idToken) token = options?.idToken;
        if (token) {
          conf.headers.Authorization = `Bearer ${token}`;
        }
        if (options?.clientId) {
          conf.headers['client-id'] = options?.clientId;
        }

        conf.headers['Accept-Language'] = conf.headers['Accept-Language'] || defaultAcceptLanguage;

        conf.name = serviceName;
        // Gets the `time-difference` value from cookie only
        // skipping from `calling-api`
        const timeDiff = getTimeDiffFromCookie({
          skipGetServerTime: true,
          // since there is no server rendering (like NextJs) so `isServer` default set to false
          isServer: false
        });
        conf.timeDiff = timeDiff;

        // updating `kong-hmac` headers
        kongHMAC.updateHeaders(conf);

        conf.metadata = { startTime: +moment().unix() };

        return conf;
      });
    });
  }

  return true;
};

export const InitRequest = async ({ token, clientId, isDoctorSide }) => {
  const [err, response] = await LocaleAPI.GetInitServiceURL(clientId);
  if (err) throw new Error(err);

  const options = response?.data?.data;
  if (token) {
    options.tokenFromPostMessage = token;
  }

  if (clientId) {
    options.clientId = clientId;
  }

  if (isDoctorSide) {
    options.isDoctorSide = isDoctorSide;
  }

  ReinitAPIRequest(options);

  return options;
};

export const getCancelToken = () => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  return { source };
};

export default OkaAxios;
