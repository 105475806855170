import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 16px;
`;

export const ButtonContainer = styled.div`
  max-width: 343px;
  margin-inline: auto;
  margin-bottom: 24px;
`;

export const Link = styled.a`
  font-size: 18px;
  line-height: 1.56;
  text-align: center;
  color: #007acc;
  margin-inline: auto;
  display: block;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export const Title = styled.div`
  max-width: 725px;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #363d45;
  margin: auto;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

export const Description = styled.div`
  max-width: 600px;
  font-size: 18px;
  line-height: 1.56;
  text-align: center;
  color: #363d45;
  margin-inline: auto;
  margin-bottom: 32px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const Img = styled.img`
  width: 244px;
  height: 195px;
  margin: auto;
  display: block;
  margin-bottom: 51px;
`;
