import { CaretLeft } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';

const Left = ({ ...props }) => (
  <button type="button" {...props}>
    <CaretLeft size={16} color={Colors.grayscale.RhinoGrey} />
  </button>
);

export default Left;
