import LoadingDots from 'components/LoadingDots';
import { Container } from './styles';

const LoadingModal = ({ active }) => {
  return (
    <Container active={active}>
      <div className="d-flex align-items-center flex-fill justify-content-center flex-column">
        <LoadingDots isLoading={active} className="justify-content-center" />
      </div>
    </Container>
  );
};

export default LoadingModal;
