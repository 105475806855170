import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useGlobalConfigStore, useAudioPreviewConfigStore } from 'zustandStore';
import ToolBox from 'components/Toolbox';
import InfoView from '../video/partial/info-view';
import { PreMeetingGlobalStyle, VideoPreview } from './styles';
import useChat from 'libs/hooks/useChat';
import useToast from 'libs/hooks/useToast';
import ConferenceAPI from 'libs/api/conference';
import _isEmpty from 'lodash.isempty';
import { setStorage } from 'libs/storage';

const PreMeeting = ({
  onJoinRoom,
  setIsSessionEnd,
  showInfo = false,
  setShowChat = () => {},
  setShowFullChat = () => {},
  setShowVideo = () => {},
  isTestingMode = false,
  videoTrack: videoSrcTrack = null
}) => {
  // const toggleWebcamOn = useGlobalConfigStore(state => state.toggleWebcamOn);
  // const toggleMicOn = useGlobalConfigStore(state => state.toggleMicOn);
  // const micOn = useGlobalConfigStore(state => state.micOn);
  // const webcamOn = useGlobalConfigStore(state => state.webcamOn);
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  // const webcamDeviceId = useGlobalConfigStore(state => state.webcamDeviceId);
  const micDeviceId = useGlobalConfigStore(state => state.micDeviceId);
  const setWebcamDeviceId = useGlobalConfigStore(state => state.setWebcamDeviceId);
  const setMicDeviceId = useGlobalConfigStore(state => state.setMicDeviceId);
  const setMeetingStarted = useGlobalConfigStore(state => state.setMeetingStarted);
  const [videoTrack, setVideoTrack] = useState(null);
  const videoPlayerRef = useRef();
  const audioTracksRef = useRef();
  const [webcamLists, setWebcamLists] = useState([]);
  const [micLists, setMicLists] = useState([]);
  const [toggleShowDeviceList, setToggleShowDeviceList] = useState(false);
  const [toggleShowMicDeviceList, setToggleShowMicDeviceList] = useState(false);
  const [WebCamOn, setWebCamOn] = useState(true);
  const [MicOn, setMicOn] = useState(true);
  const setAudioPreviewDeviceId = useAudioPreviewConfigStore(state => state.setAudioPreviewDeviceId);

  useEffect(() => {
    if (!MicOn) {
      audioTracksRef?.current?.forEach(track => {
        track.stop();
      });
    }
    setStorage('MicOn', MicOn);
    setStorage('WebCamOn', WebCamOn);
  }, [WebCamOn, MicOn]);

  const { t } = useTranslation();
  const { show } = useToast();
  const {
    value: { appointment, doctor, isDoctor },
    action
  } = useChat();
  const isODDConsultation = appointment?.isODDConsultation ?? false;

  const handleToggleMic = () => {
    setMicOn(state => !state);
    // setStorage('MicOn', MicOn);
    // toggleMicOn();
  };

  const handleClickOutside = () => {
    setToggleShowDeviceList(show => false);
    setToggleShowMicDeviceList(show => false);
  };

  const handleToggleLocalWebcam = async () => {
    if (!videoTrack) {
      getVideo();
    } else {
      if (videoTrack) {
        if (WebCamOn) {
          videoTrack.stop();
          videoPlayerRef.current.srcObject = null; // remove current srcObject video
        } else getVideo();

        setWebCamOn(state => !state);
        // setStorage('WebCamOn', WebCamOn);

        // toggleWebcamOn();
      }
    }
  };

  const hasJoinedMultipleDevice = async () => {
    const payload = { appointmentNumber: appointment?.appointmentNumber };
    // eslint-disable-next-line new-cap
    const [err, response] = await ConferenceAPI.GetActiveParticipant(payload);

    // find participant match with current user
    const hasJoined = response?.data?.data?.find(({ user_id: userId = '' }) =>
      userId.includes(isDoctor ? 'doctor' : 'patient')
    );

    return { hasJoined, err };
  };

  const handleJoinRoom = async e => {
    e.preventDefault();

    if ((isDoctor && isODDConsultation) || !isODDConsultation) {
      const { hasJoined, err } = await hasJoinedMultipleDevice();
      // Show toast error when failed to get participant active
      if (hasJoined || err) {
        show({
          type: 'danger',
          message: err
            ? err?.response
            : t(
                'You are already logged in from one device, please close that session before joining from a second device.'
              )
        });

        return;
      }
    }

    setShowVideo(true);
    if (!isDoctor && isODDConsultation) {
      setShowChat(false);
      setShowFullChat(false);

      toggleState({ isPatientJoinTheCall: true });

      action?.setIsShowAppDrawer(false);
    }
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(null);
    }
    const data = {
      appointmentNumber: appointment.appointmentNumber,
      message: {
        format: 'video_start',
        text: `${appointment.participantName}`,
        message: `${appointment.participantName}`,
        time: new Date().toISOString()
      }
    };
    action.saveChat(data);
    setMeetingStarted(true);

    onJoinRoom();
    // setIsSessionEnd(false);
  };

  const handleJoinChat = async e => {
    e.preventDefault();
    const { hasJoined, err } = await hasJoinedMultipleDevice();
    // Show toast error when failed to get participant active
    if (hasJoined || err) {
      show({
        type: 'danger',
        message: err
          ? err?.response
          : t(
              'You are already logged in from one device, please close that session before joining from a second device.'
            )
      });

      return;
    }

    setShowVideo(false);

    // toggle mic and webcam off
    toggleState({
      webcamOn: false,
      micOn: false,
      leavedScreenStates: { webcamOn: false, micOn: false }
    });

    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(null);
    }

    setMeetingStarted(true);
    onJoinRoom();
    setIsSessionEnd(false);

    setTimeout(() => {
      setShowFullChat(true);
    }, 100);
  };

  const getVideo = async (deviceId = '') => {
    console.log('videoSrcTrack: ', videoSrcTrack);
    if (videoPlayerRef.current) {
      try {
        // const videoTracks = videoSrcTrack.getVideoTracks();
        // const getWebcamDeviceId = videoSrcTrack.getTracks().map(track => track.getSettings().deviceId);

        // const videoTrackStream = videoTracks.length ? videoTracks[0] : null;

        // videoPlayerRef.current.srcObject = new MediaStream([videoTrackStream]);
        // videoPlayerRef.current.play();

        // if (getWebcamDeviceId?.length) setWebcamDeviceId(getWebcamDeviceId[0]);
        // setVideoTrack(videoTrackStream);
        const videoConstraints = {
          video: {
            width: 1280,
            height: 720,
            deviceId: deviceId
          }
        };

        await navigator.mediaDevices
          .getUserMedia(videoConstraints)
          .then(stream => {
            getDevices();

            const videoTracks = stream.getVideoTracks();
            const getWebcamDeviceId = stream.getTracks().map(track => track.getSettings().deviceId);

            const videoTrackStream = videoTracks.length ? videoTracks[0] : null;

            videoPlayerRef.current.srcObject = new MediaStream([videoTrackStream]);
            videoPlayerRef.current.play();

            if (getWebcamDeviceId?.length) setWebcamDeviceId(getWebcamDeviceId[0]);
            setVideoTrack(videoTrackStream);
          })
          .catch(err => {
            toggleState({ webcamOn: false });
            console.error(`err: ${err}`);
          });
      } catch (err) {
        console.error('err: ', err);
      }
    }
  };

  const setMicStream = async () => {
    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        getMics();
        const audioTracks = stream.getAudioTracks();
        audioTracksRef.current = audioTracks;
        const getMicDeviceId = audioTracks.map(track => track.getSettings().deviceId);
        const hasSelectedDevice = micLists.map(list => list.deviceId).includes(micDeviceId);

        if (!hasSelectedDevice) {
          if (getMicDeviceId?.length) setMicDeviceId(getMicDeviceId[0]);
          if (MicOn) toggleState({ micOn: false });
        }
      })
      .catch(err => {
        toggleState({ micOn: false });
        console.error(`err: ${err}`);
      });
  };

  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const getWebcams = devices.filter(d => d.kind === 'videoinput');

      if (getWebcams) {
        const webcams = getWebcams.map((webcam, index) => {
          const label = _isEmpty(webcam.label) ? `Video ${index + 1}` : webcam.label;

          return { deviceId: webcam.deviceId, label: label };
        });

        setWebcamLists(webcams);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMics = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const getMics = devices.filter(d => d.kind === 'audioinput');

      if (getMics) {
        const mics = getMics.map((mic, index) => {
          const label = _isEmpty(mic.label) ? `Audio ${index + 1}` : mic.label;

          return { deviceId: mic.deviceId, label: label };
        });

        setMicLists(mics);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(show => !show);
    setToggleShowMicDeviceList(show => false);
  };

  const handleShowMicDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(show => false);
    setToggleShowMicDeviceList(show => !show);
  };

  const handleChangeCamera = cameraId => {
    if (cameraId) {
      getVideo(cameraId);
      setWebcamDeviceId(cameraId);
      setToggleShowDeviceList(false);
      setToggleShowMicDeviceList(false);
      // toggleState({ webcamOn: true });
    }
  };

  const handleChangeMic = micId => {
    if (micId) {
      setMicDeviceId(micId);
      setToggleShowDeviceList(false);
      setToggleShowMicDeviceList(false);
      setAudioPreviewDeviceId(micId);
      // toggleState({ micOn: true });
    }
  };

  useEffect(() => {
    if (!videoTrack) {
      getVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoSrcTrack]);

  useEffect(() => {
    setMicStream();
  }, []);

  useEffect(() => {
    navigator.mediaDevices.ondevicechange = () => {
      setMicStream();
    };
    return () => {
      audioTracksRef?.current?.forEach(track => {
        track.stop();
      });
    };
  }, []);

  useEffect(() => {
    const defaultMicLabel = micLists.find(mic => mic.deviceId === 'default')?.label;
    if (defaultMicLabel) {
      const micsByLabel = micLists.filter(mic => defaultMicLabel.includes(mic.label) && mic.deviceId !== 'default');
      setAudioPreviewDeviceId(micsByLabel?.[0]?.deviceId || 'default');
    } else {
      setAudioPreviewDeviceId(micDeviceId);
    }
  }, [micLists]);

  const showGeneralAction = (isODDConsultation && isDoctor) || !isODDConsultation;

  return (
    <>
      <PreMeetingGlobalStyle isDoctor={isDoctor} isODDConsultation={isODDConsultation} />
      <div className="video-embed active">
        <div id="lobby-screen" className="premeeting-screen">
          <div className="premeeting-screen-header-container">
            <div id="header-title-container" className="content">
              <h2 className="title">
                {isODDConsultation && !isDoctor
                  ? t('Ready to start the video call?')
                  : t('This consultation is ready to start')}
              </h2>
            </div>
            {isODDConsultation && !isDoctor && (
              <div className="title-extra">{t('{{doctor}} is already in the call', { doctor: doctor?.fullName })}</div>
            )}
          </div>

          <div className="premeeting-screen-preview-container">
            <VideoPreview id="preview" className="content">
              <video autoPlay playsInline muted ref={videoPlayerRef} controls={false} className="video-pre-meeting" />
              <div className="preview-caption-text">{t('Video Preview')}</div>
            </VideoPreview>
            <div id="preview-navigation" className="content">
              <div className="prejoin-button-container">
                <button className="button green action-btn" onClick={handleJoinRoom}>
                  {showGeneralAction && (
                    <img
                      src="https://img.okadoc.com/photos/block_images/img/icon/videocam.svg"
                      className="icon-button"
                      alt="preview video"
                    />
                  )}
                  {isODDConsultation && !isDoctor ? t('Start Call') : t('Start with video')}
                </button>
              </div>
              {showGeneralAction && (
                <div className="prejoin-chat-button">
                  <p className="prejoin-chat-button__text">
                    {t('Or you can')}
                    <span className="prejoin-chat-button__action" onClick={handleJoinChat}>
                      <img
                        src="https://img.okadoc.com/photos/block_images/img/icon/ic-chatbox.svg"
                        alt="chatbox icon"
                      />
                      {t('Start with chat only')}
                    </span>
                  </p>
                </div>
              )}
              <ToolBox
                micOn={MicOn}
                webcamOn={WebCamOn}
                handleToggleMic={handleToggleMic}
                handleToggleWebcam={handleToggleLocalWebcam}
                showSwitchView={false}
                showEndCall={false}
                handleShowDeviceList={handleShowDeviceList}
                handleChangeCamera={handleChangeCamera}
                showDeviceList={toggleShowDeviceList}
                deviceLists={webcamLists}
                hasMultipleSources
                showAudioPreview
                micLists={micLists}
                handleShowMicDeviceList={handleShowMicDeviceList}
                showMicList={toggleShowMicDeviceList}
                handleChangeMic={handleChangeMic}
                handleClickOutside={handleClickOutside}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classnames('sidebar', 'chat--sidebar', { hide: true })}>
        <InfoView t={t} isTestingMode={isTestingMode} />
      </div>
    </>
  );
};

PreMeeting.propTypes = {
  onJoinRoom: PropTypes.func,
  setShowFullChat: PropTypes.func,
  setIsSessionEnd: PropTypes.func,
  setShowVideo: PropTypes.func,
  setShowChat: PropTypes.func,
  isTestingMode: PropTypes.bool,
  showInfo: PropTypes.bool,
  micOn: PropTypes.bool,
  webcamOn: PropTypes.bool
};

export default PreMeeting;
