import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  transform: rotate(-90deg);
  > svg {
    fill: #4e5e6c;
    width: 38px;
    ${({ level }) => css`
      > rect:nth-child(-n + ${level}) {
        fill: #31b76a;
      }
    `}
  }
`;
