import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex: 1 1 auto;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  transition: 0.3s all ease;
  visibility: hidden;
  opacity: 0.3;
  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
      z-index: 3000;
    `};
  > div {
    display: flex;
    flex: 1 1 auto;
  }
`;
