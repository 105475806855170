/* eslint-disable new-cap */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { useFilePicker } from 'use-file-picker';
import { usePubSub } from '@videosdk.live/react-sdk';
import { useTranslation } from 'react-i18next';
import { getQueryParams } from 'libs/url';
import { sendMessage } from 'libs/message';
import useChat from 'libs/hooks/useChat';
import AppointmentAPI from 'libs/api/appointment';
import LocaleAPI from 'libs/api/locale';
import useToast from 'libs/hooks/useToast';
import { rgxSpecialChar } from 'libs/regex-pattern';
import { MAX_LENGTH_DOCUMENT_NAME, DOCUMENT_TYPE_ID, DEFAULT_WIDTH_ELEMENT } from './constants';
import { EVENT_PUBSUB, DEFAULT_MAX_UPLOAD_SIZE } from 'libs/constant';

export const MAX_UPLOAD_SIZE = process.env.REACT_APP_MAX_UPLOAD_SIZE || DEFAULT_MAX_UPLOAD_SIZE;

const useAction = ({ country, lang, isOpen = false, sideBarRef = null, wlVersion = null, onHide = () => {} }) => {
  const [widthTray, setWidthTray] = useState(DEFAULT_WIDTH_ELEMENT);
  const [form, setForm] = useState({
    document_title: '',
    tag_id: null,
    files: []
  });
  const [error, setError] = useState({
    document_title: '',
    tag_id: '',
    files: ''
  });
  const [dataTypeOption, setDataTypeOption] = useState([]);
  const [isSendLoading, setIsSendLoading] = useState(false);

  const stateRef = useRef(null);

  const { publish } = usePubSub(EVENT_PUBSUB.CHAT, {});

  const { t } = useTranslation();
  const { show } = useToast();
  const {
    value: {
      appointment: { appointmentNumber },
      doctor,
      patient
    }
  } = useChat();

  const [openFileSelector, { filesContent, errors: fileErrors, plainFiles, clear: fileClear }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['.png', '.jpeg', '.pdf', '.jpg'],
    multiple: false,
    limitFilesConfig: { min: 1, max: 1 },
    maxFileSize: MAX_UPLOAD_SIZE
  });

  const params = getQueryParams(window.location);
  const domain = params?.domain;

  useEffect(() => {
    const fetchDocumentType = async () => {
      const [, response] = await LocaleAPI.GetMasterDocumentTags();

      if (response) {
        setDataTypeOption(
          response?.data?.data
            ?.filter(d => ![DOCUMENT_TYPE_ID.ID, DOCUMENT_TYPE_ID.INSURANCE].includes(d.id))
            .map(d => ({ label: d.name, value: d.id }))
        );
      }
    };

    if (isOpen) {
      let widthElement = DEFAULT_WIDTH_ELEMENT;
      if (sideBarRef?.offsetWidth) {
        widthElement = sideBarRef?.offsetWidth;
      }

      fetchDocumentType();
      setDefaultValue();
      setWidthTray(widthElement);
    }
  }, [isOpen, sideBarRef]);

  useEffect(() => {
    let errorMessageFiles = '';
    let dataFiles = [];

    if (fileErrors.length > 0) {
      for (const value of fileErrors) {
        if (value?.fileSizeToolarge) {
          errorMessageFiles = getValidation('files', 'fileSizeToolarge');
        }
      }
    }

    if (filesContent.length > 0 && plainFiles.length > 0) {
      dataFiles = plainFiles.map((item, index) => ({
        file: filesContent[index].content,
        file_name: item.name,
        file_size: item.size,
        file_type: item.type
      }));
    }

    setForm({ ...form, files: dataFiles });
    setError({ ...error, files: errorMessageFiles });
  }, [filesContent, fileErrors, plainFiles]);

  // Intentional for payload used, since state cannot read the value on post message
  useEffect(() => {
    stateRef.current = form;
  }, [form]);

  const onChangeForm = (name, value = '') => {
    setForm({ ...form, [name]: value });

    if (name === 'tag_id' && value) {
      setError({ ...error, tag_id: '' });
    }
  };

  const onClickClearFiles = () => {
    fileClear();
  };

  const onClickSend = e => {
    e?.preventDefault();

    if (!error.document_title && !error.tag_id && !error.files) {
      if (wlVersion === '1') {
        saveDocument();
      } else {
        sendConsentDocumentByDoctor();
      }
    }
  };

  const onClickUpload = e => {
    e?.preventDefault();
    openFileSelector();
  };

  const onValidate = (type, value = '') => {
    const message = getValidation(type, value);

    setError({ ...error, [type]: message });
  };

  const saveDocument = async () => {
    const payload = {
      ...stateRef.current
    };

    setIsSendLoading(true);
    const [err, response] = await AppointmentAPI.CreateFacilityDocuments({ appointmentNumber, payload });

    if (response) {
      const meta = response?.data?.data?.meta?.chat_history?.[0] ?? {};
      const message = JSON.stringify({
        id: meta?.id,
        appointmentNumber: meta?.appointment_number,
        message: meta?.text,
        text: meta?.text,
        format: 'image', // always image for document pdf format and image format
        sender: 'doctor', // because this new upload screen just for practitioner at the moment
        filetype: payload?.files?.[0]?.file_type
      });

      publish(message, { persist: false });
      setTimeout(() => {
        onHide();
      }, 500);
    }
    if (err) {
      show({
        type: 'danger',
        message: t('Something went wrong, please try again')
      });
    }
    setIsSendLoading(false);
  };

  const getValidation = (type, value = '') => {
    let message = '';

    if (type === 'document_title') {
      if (value && value.match(rgxSpecialChar)) {
        message = 'Document Name must only contain letters and numbers.';
      } else if (value && value.length > MAX_LENGTH_DOCUMENT_NAME) {
        message = 'Document Name can not be more than 255 chars.';
      } else if (value === '') {
        message = 'Document Name is required';
      }
    } else if (type === 'tag_id' && value === '') {
      message = 'Document Type is required';
    } else if (type === 'files' && value === 'fileSizeToolarge') {
      message = 'Maximum size for file upload is {{size}}mb';
    }
    return message;
  };

  const sendConsentDocumentByDoctor = () => {
    sendMessage(
      {
        message: {
          type: 'upload_document',
          created_by: doctor?.name ?? doctor?.fullName,
          document_title: form.files[0]?.file_name ?? '',
          last_updated_time: new Date().toISOString(),
          facility_name: `${patient?.first_name} ${patient?.last_name}`,
          country,
          lang
        }
      },
      domain
    );
  };

  const setDefaultValue = () => {
    setForm({ document_title: '', tag_id: null, files: [] });
    setError({ document_title: '', tag_id: '', files: '' });
    fileClear();
  };

  return {
    error,
    form,
    dataTypeOption,
    widthTray,
    isSendLoading,
    saveDocument,
    onChangeForm,
    onClickClearFiles,
    onClickSend,
    onClickUpload,
    onValidate
  };
};

export default useAction;
