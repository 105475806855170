import to from '../../await-to';
import OkaAxios from '../../axios';

export default class ConferenceAPI {
  static RequestRooom = async ({ appointmentNumber }) => {
    const endpoint = `/conference/room`;

    return to(OkaAxios.conference.post(endpoint, { appointment_number: appointmentNumber }));
  };

  static GetActiveParticipant = async ({ appointmentNumber }) => {
    const endpoint = `/conference/participants/${appointmentNumber}/active`;

    return to(OkaAxios.conference.get(endpoint));
  };
}
