import runtimeConfig from 'libs/runtime-config';

const flags = JSON.parse(runtimeConfig.REACT_APP_OKA_PUBLIC_FEATURE_FLAGS ?? '[]');

export const FEATURE_ODD_APPOINTMENT = 'odd-appointment';
export const FEATURE_TELEMEDICINE_CONSENT = 'telemedicine-consent-flow';
export const FEATURE_IDLING_TIMER = 'idling-timer';

const isAvailable = (featureName = []) => {
  if (!Array.isArray(featureName)) {
    return false;
  }
  const match = flags.filter(f => f.isActive && featureName.includes(f.name)) || [];

  return match.length > 0;
};

export default isAvailable;
