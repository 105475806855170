export const log = (...args) => {
  const logs = [];
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    console.log(...args);
  } else {
    logs.push(...args);
  }

  return logs;
};
