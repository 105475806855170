/* eslint-disable no-undefined */
import { useContext } from 'react';
import { ChatContext } from '../contexts/chats';

const useChat = () => {
  const context = useContext(ChatContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useChat was used outside of its Provider');
  }

  return context;
};

export default useChat;
