import runtimeConfig from 'libs/runtime-config';
import { useCallback, useEffect, useRef, useState } from 'react';

const IDLING_TIME = +runtimeConfig?.REACT_APP_OKA_PUBLIC_IDLING_TIME || 300;
const IDLING_TIME_NO_RESPONSE = +runtimeConfig?.REACT_APP_OKA_PUBLIC_IDLING_TIME_NO_RESPONSE || 300;

const useIdlingTimer = () => {
  const idleInterval = useRef(null);
  const idleNoResponseInterval = useRef(null);

  const [isIdle, setIsIdle] = useState(false);
  const [isIdleNoResponse, setIsIdleNoResponse] = useState(false);

  const [idleCountDown, setIdleCountDown] = useState(IDLING_TIME);
  const [idleNoResponseCountDown, setIdleNoResponseCountDown] = useState(IDLING_TIME_NO_RESPONSE);
  const [timer, setTimer] = useState({
    idlingTimer: false,
    idlingNoResponseTimer: false
  });

  const stopInterval = whichInterval => {
    if (whichInterval?.current) {
      clearInterval(whichInterval.current);
      whichInterval.current = null;
    }
  };

  const stopIdleTimer = useCallback(() => {
    stopInterval(idleInterval);
    stopInterval(idleNoResponseInterval);
    setTimer(prev => ({ ...prev, idlingTimer: false, idlingNoResponseTimer: false }));

    setIsIdle(false);
    setIsIdleNoResponse(false);

    setIdleCountDown(IDLING_TIME);
    setIdleNoResponseCountDown(IDLING_TIME_NO_RESPONSE);
  }, []);

  // idling timer
  useEffect(() => {
    if (timer.idlingTimer && !idleInterval.current) {
      idleInterval.current = setInterval(() => setIdleCountDown(timeLeft => timeLeft - 1), 1000);
    }
    console.log(`IDLE (waiting for idle) in: ${idleCountDown}`);
  }, [timer.idlingTimer, idleCountDown]);

  // idling no response timer
  useEffect(() => {
    if (timer.idlingNoResponseTimer && !idleNoResponseInterval.current) {
      if (idleNoResponseCountDown !== IDLING_TIME_NO_RESPONSE) setIdleNoResponseCountDown(IDLING_TIME_NO_RESPONSE);

      idleNoResponseInterval.current = setInterval(() => setIdleNoResponseCountDown(timeLeft => timeLeft - 1), 1000);
    }
    console.log(`IDLE NORESPONSE (waiting for no response) in: ${idleNoResponseCountDown}`);
  }, [timer.idlingNoResponseTimer, idleNoResponseCountDown]);

  useEffect(() => {
    if (idleCountDown <= 0) {
      stopInterval(idleInterval);
      console.log('IDLE: true');
      setIsIdle(true);
      setIdleCountDown(IDLING_TIME);
      setTimer({ idlingTimer: false, idlingNoResponseTimer: true });
    }

    if (idleNoResponseCountDown <= 0) {
      stopInterval(idleNoResponseInterval);
      console.log('IDLE NO RESPONSE: true');
      setIsIdleNoResponse(true);
      setIdleNoResponseCountDown(IDLING_TIME_NO_RESPONSE);
      setTimer({ idlingTimer: false, idlingNoResponseTimer: false });
    }
  }, [idleCountDown, idleNoResponseCountDown]);

  useEffect(() => {
    return () => {
      clearInterval(idleInterval.current);
      clearInterval(idleNoResponseInterval.current);
    };
  }, []);

  return { setTimer, isIdle, isIdleNoResponse, stopIdleTimer, timer, IDLING_TIME_NO_RESPONSE, idleNoResponseCountDown };
};

export default useIdlingTimer;
