/* eslint-disable new-cap */
// Modules
import React, { useEffect } from 'react';
import { usePubSub, useMeeting, useParticipant } from '@videosdk.live/react-sdk';
import PropTypes from 'prop-types';

// Libs
import {
  EVENT_PUBSUB,
  EVENT_MESSAGE_IFRAME,
  EVENT_TYPE_IFRAME,
  CONSULTATION_STATUS,
  EVENTS_TYPE_SSE
} from 'libs/constant';
import { getQueryParams } from 'libs/url';
import { sendMessage } from 'libs/message';
import { GetEventTelemedicine } from 'libs/services/eventsources';
import useChat from 'libs/hooks/useChat';
import { getFullDatetime } from 'libs/date';
import { useServerEventsStore, useGlobalConfigStore } from 'zustandStore';

const MeetingWrapper = ({ children, isTestingMode, isBrowserMode, onSwitchChat, participantId, showFullChat }) => {
  // Hooks
  const { value, action } = useChat();
  const meetingSdk = useMeeting();
  const setIsEndSession = useGlobalConfigStore(state => state.setIsEndSession);
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const { isShowCSAT, isEndSession, showCSATDone } = useGlobalConfigStore(
    ({ isShowCSAT, isEndSession, showCSATDone }) => ({
      isShowCSAT,
      isEndSession,
      showCSATDone
    })
  );

  const { publish, messages } = usePubSub(EVENT_PUBSUB.CONSULTATION_END);
  const { publish: publishChat } = usePubSub(EVENT_PUBSUB.CHAT);
  const participantSdk = useParticipant(participantId);
  const params = getQueryParams(window.location);
  const domain = params?.domain;
  const shallRecord = value?.telemedicine?.shallrecorded;
  const appointmentNumber = value?.appointment?.appointmentNumber;
  const isDoctorView = value?.isDoctor;
  const updateTelemedicineStartTime = useServerEventsStore(state => state.updateTelemedicineStartTime);
  const isCSATEnabled = value?.appointment?.fgCSAT || false;

  const handleShowCSAT = () => {
    participantSdk?.disableWebcam();
    participantSdk?.disableMic();
    onSwitchChat();
    toggleState({ isShowCSAT: true });
  };

  useEffect(() => {
    if (isCSATEnabled && !isShowCSAT && !showCSATDone && !isDoctorView && isEndSession) {
      handleShowCSAT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoctorView, isEndSession, isCSATEnabled, isShowCSAT, showCSATDone]);

  useEffect(() => {
    const latestMessage = messages[messages.length - 1];

    if (latestMessage) {
      const dataMessage = JSON.parse(latestMessage?.message || '{}');
      sendMessage({
        message: {
          type: EVENT_MESSAGE_IFRAME.PAYMENT_SUCCESS,
          data: {
            total: dataMessage?.total,
            currency: dataMessage?.currency,
            payment_method_code: dataMessage?.payment_method_code,
            fg_csat: isCSATEnabled
          }
        },
        domain
      });

      if (shallRecord) {
        meetingSdk?.stopRecording();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, messages, shallRecord, isShowCSAT, isDoctorView, isCSATEnabled, showCSATDone]);

  useEffect(() => {
    const initialLoad = event => {
      const { type, data = {}, origin } = event;
      const originUrl = new URL(origin);

      if (originUrl.hostname === domain || (type === 'doctor' && domain === 'doctor.okadoc.local')) {
        try {
          const dataMessage = data && JSON.parse(data || '{}');

          if (dataMessage?.type === EVENT_TYPE_IFRAME.DOCTOR_APP_ENDSESSION) {
            setIsEndSession(true);
            const payment = dataMessage?.data?.payment;

            // Do publish the payment data to the pubSub
            publish(JSON.stringify(payment), { persist: true });
          }

          if (dataMessage?.type === EVENT_TYPE_IFRAME.SWITCH_SCREEN_CHAT) {
            // do send save chat with format `switch_to_chat`
            participantSdk?.disableWebcam();
            participantSdk?.disableMic();

            // Re-enable list of consultation on the doctor web
            sendMessage({
              message: {
                type: 'telemedicine_stream_status',
                streamData: { practitioner_status: CONSULTATION_STATUS.LEFT_CONSULTATION }
              },
              domain
            });
            onSwitchChat();
          }

          // This event handling send event chat/video end when user leave the meeting / appointment page
          if (dataMessage?.type === EVENT_TYPE_IFRAME.LEFT_MEETING) {
            const { doctor, patient, isDoctor, appointment } = value;
            const remoteUser = !isDoctor ? doctor : patient;
            const userName = isDoctor ? remoteUser?.title : [remoteUser?.first_name, remoteUser?.last_name].join(' ');
            const format = !showFullChat ? 'video_end' : 'chat_end';
            const eventEndCall = {
              ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
              format,
              message: format,
              text: format,
              time: getFullDatetime,
              sender: !isDoctor ? 'patient' : 'doctor'
            };
            const eventPayload = JSON.stringify(eventEndCall);

            action.saveChat({
              appointmentNumber: appointment.appointmentNumber,
              message: eventEndCall
            });
            publishChat(eventPayload, { persist: false });
          }
        } catch (err) {
          console.error('error -> ', err);
        }
      }
    };

    if (!isTestingMode && !isBrowserMode) {
      window.addEventListener('message', initialLoad);

      return () => window.removeEventListener('message', initialLoad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTestingMode, isBrowserMode, domain, publish]);

  useEffect(() => {
    const eventSource = GetEventTelemedicine(appointmentNumber);

    const getRealtimeDataMessage = e => {
      try {
        const message = JSON.parse(e?.data || '{}');
        const event = e?.event || '';
        if (message) {
          toggleState({
            participantStatus: { practitioner: message?.practitioner_status, patient: message?.patient_status }
          });
          updateTelemedicineStartTime(message.start_time);
          const msg =
            event === EVENTS_TYPE_SSE.SESSION_END
              ? {
                  ...message,
                  practitioner_status: CONSULTATION_STATUS.COMPLETED
                }
              : message;

          if (
            msg?.practitioner_status === CONSULTATION_STATUS.COMPLETED ||
            msg?.patient_status === CONSULTATION_STATUS.COMPLETED
          ) {
            setIsEndSession(true);
          }

          sendMessage({
            message: {
              type: 'telemedicine_stream_status',
              streamData: msg
            },
            domain
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    eventSource.onmessage = getRealtimeDataMessage;
    eventSource.onerror = () => {
      eventSource.close();
    };
    return () => {
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentNumber, isDoctorView, domain]);

  return <>{children}</>;
};

MeetingWrapper.propTypes = {
  isTestingMode: PropTypes.bool,
  isBrowserMode: PropTypes.bool,
  onSwitchChat: PropTypes.func
};

export default MeetingWrapper;
