import React from 'react';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="loading-screen">
      <div className="loading-screen__message">
        <div className="loading-dots">
          <span className="loading-dots__circle loading-dots--circle-1"></span>
          <span className="loading-dots__circle loading-dots--circle-2"></span>
          <span className="loading-dots__circle loading-dots--circle-3"></span>
        </div>
        <div className="loading-screen__message-text">
          <h3 className="text-black">{t('Loading')}</h3>
        </div>
      </div>
    </div>
  );
};

export default Loading;
