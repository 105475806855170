import { useEffect, useRef, useState } from 'react';
import theDate, { getDuration, getTimeSecond } from 'libs/date';

export const useTimer = (startTime = '') => {
  const refDuration = useRef();
  const getStartTime = getTimeSecond(startTime);

  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [intervalSeconds, setIntervalSeconds] = useState(0);
  const intervalId = useRef();

  const handleStartTimer = () => setIsTimerStarted(true);
  const handleStopTimer = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      setIsTimerStarted(false);
    }
  };
  const format = t => {
    if (isNaN(t)) {
      return '00';
    }
    return t < 10 && t >= 0 ? '0' + t : t;
  };

  useEffect(() => {
    if (isTimerStarted) {
      intervalId.current = setInterval(() => {
        setIntervalSeconds(intervalSeconds + 1);
        const timeConstruct = theDate.utc();
        const diffTime = timeConstruct.diff(getStartTime, 'millisecond');
        refDuration.current = getDuration(Math.abs(diffTime));
      }, 1000);
    }
    return () => {
      clearInterval(intervalId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimerStarted, intervalSeconds]);

  if (!refDuration.current) {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
      startTimer: handleStartTimer,
      stopTimer: handleStopTimer
    };
  }

  return {
    hours: format(refDuration.current.hours()),
    minutes: format(refDuration.current.minutes()),
    seconds: format(refDuration.current.seconds()),
    startTimer: handleStartTimer,
    stopTimer: handleStopTimer
  };
};
